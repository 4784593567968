import React, { useEffect, useState } from 'react';

function AuthenticatedUserForm() {
  const [formData, setFormData] = useState({
    firstName: '',
    familyName: '',
    dob: '',
    gender: '',
    country: '',
    governorate: '',
    email: '',
    mobile: '',
    id: '',
    degree: '',
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, [])

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleCancel = () => {
    // Redirect to the select-user page or perform any other action
    // You can use react-router-dom for navigation
    // Example: history.push('/select-user');
  };

  return (
    <div className="container pt-5 pb-5">
      <section className="urs-type-form">
        <div className="container my-5">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <h1 className="text-center">Authenticated User</h1>
              <p className="text-center usr-type-description">
                Please complete the information presented below
              </p>
              <form className="user_info_form shadow-sm" onSubmit={handleSubmit}>
                <div className="row g-3">
                  <div className="col-md-6">
                    <label htmlFor="first-name" className="form-label">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      id="first-name"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="family-surname" className="form-label">
                      Family Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Family Name"
                      id="family-surname"
                      name="familyName"
                      value={formData.familyName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="dob" className="form-label">
                      Date of Birth
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Date of Birth"
                      id="dob"
                      name="dob"
                      value={formData.dob}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="gender" className="form-label">
                      Gender
                    </label>
                    <select
                      name="gender"
                      id="gender"
                      className="form-control"
                      value={formData.gender}
                      onChange={handleChange}
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="country" className="form-label">
                      Country
                    </label>
                    <select
                      name="country"
                      id="country"
                      className="form-control"
                      value={formData.country}
                      onChange={handleChange}
                    >
                      <option value="">Select Country</option>
                      <option value="egypt">Egypt</option>
                      <option value="saudi-arabia">Saudi Arabia</option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="govern" className="form-label">
                      Governorate
                    </label>
                    <select
                      name="governorate"
                      id="govern"
                      className="form-control"
                      value={formData.governorate}
                      onChange={handleChange}
                    >
                      <option value="">Select Governorate</option>
                      <option value="cairo">Cairo</option>
                      <option value="riyadh">Riyadh</option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="your-email" className="form-label">
                      Your Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      id="your-email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="your-mobile" className="form-label">
                      Your Mobile
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Mobile Number"
                      id="your-mobile"
                      name="mobile"
                      value={formData.mobile}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="your-id" className="form-label">
                      National ID/Passport
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      placeholder="ID / Passport"
                      id="your-id"
                      name="id"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="your-degree" className="form-label">
                      Highest Level of Degree
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Degree"
                      id="your-degree"
                      name="degree"
                      value={formData.degree}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-3 mb-3">
                        <button
                          type="submit"
                          className="btn register_btn w-100 fw-bold"
                        >
                          Register
                        </button>
                      </div>
                      <div className="col-md-3">
                        <button
                          type="button"
                          onClick={handleCancel}
                          className="btn btn-secondary w-100 fw-bold back-btn"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AuthenticatedUserForm;
