import { FormControlLabel, Switch, Tooltip, Typography, alpha, styled } from "@mui/material";
import axios from "axios";
import QRCode from "qrcode.react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ClipLoader } from 'react-spinners'
import { toast } from "react-toastify";
import { Encrypt } from "../Components/Helper";

function MultiSteps() {
  const navigate = useNavigate();
  const [isloading, setIsLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(0);
  const summary = {
    type: {
      'R': 'Registered',
      'T': 'Trusted',
      'A': 'Authenticated'
    },
    reach: {
      'R': '3k',
      'T': '10k',
      'A': '20k'
    },
    visibility: {
      'true': 'Public',
      'false': 'Private',
    },
    duration: {
      'R': 7,
      'T': 15,
      'A': 30
    }
  }

  const limits = {
    'R': [30, 90],
    'T': [21, 180],
    'A': [14, 270]
  }
  const [intervalId, setIntervalId] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [usertype, setUserType] = useState('')
  const [id, setId] = useState(-1)
  const [imagePreview, setImagePreview] = useState(
    "../../assets/images/dummy.webp"
  );

  const userTypeDescription = () => {
    const tokens = pollData.usersAllowed.split('');

    let result = summary.type[tokens[0]];
    if (tokens.length === 2)
      result += ' & ' + summary.type[tokens[1]]
    else if (tokens.length === 3)
      result += ', ' + summary.type[tokens[1]] + ' & ' + summary.type[tokens[2]]
    return result
  }

  const user = localStorage.getItem('token');
  const [userpoll, setUserPoll] = useState('')
  useEffect(() => {
    async function fetchUser() {
      try {
        const response = await axios.post('https://www.app.glocalpolls.com/api/get-user/', { token: user });
        setUserPoll(response.data['email'])
        setUserType(response.data['type'])
      } catch (error) {
        toast.error('User not authenticated');
        localStorage.removeItem('token')
        navigate('/home')
      }
    }

    fetchUser();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, [])

  const [pollData, setPollData] = useState({
    visibility: '',
    results: '',
    usersAllowed: '',
    name: "",
    image: '',
    questionType: '',
    scale: '',
    questions: [{ text: "", image: null }],
    category: 's',
    top5: false,
    duration: 30
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPollData({ ...pollData, [name]: value });
    localStorage.setItem(userpoll, JSON.stringify({ ...pollData, [name]: value }));
  };

  const handleTop5Change = () => {
    setPollData({ ...pollData, top5: !pollData.top5 });
    localStorage.setItem(userpoll, JSON.stringify({ ...pollData, top5: !pollData.top5 }));
  };


  useEffect(() => {
    const fetchData = () => {
      const draft = localStorage.getItem(userpoll);
      try {
        if (draft && userpoll) {
          const parsedData = JSON.parse(draft);

          if (parsedData.usersAllowed && usertype !== 'A') {
            parsedData.usersAllowed = parsedData.usersAllowed.replace(/A/g, '');
          }
          if (usertype === 'R') {
            parsedData.usersAllowed = parsedData.usersAllowed.replace(/T/g, '');
          }

          setPollData(parsedData);
        }
      } catch (error) {
      }
    }
    fetchData();
  }, [userpoll])


  const handleImageChange = (e) => {
    e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];

    if (file) {
      const allowedExtensions = [".png", ".jpg", ".jpeg"];
      const extension = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();

      if (allowedExtensions.includes(extension)) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
        setPollData({ ...pollData, image: file })
        localStorage.setItem(userpoll, JSON.stringify({ ...pollData, image: file }));
      } else {
        toast.error("Invalid file type. Please upload a PNG, JPG, or JPEG file.");
      }
    }
  };

  const handleNext = async () => {
    setUserType('T')
    if (currentStep < 2) {
      if (currentStep === 0) {
        if (!pollData.visibility || !pollData.results || !pollData.usersAllowed) {
          toast.error('Please fill in all fields before proceeding to the next step.');
          return;
        }
      } else if (currentStep === 1) {
        // Add validation logic for step 1 fields
        // let invalid = false;
        // if (usertype === 'R') {
        //     if (pollData.duration < 30 || pollData.duration > 90)
        //       invalid = true

        //   if(invalid)
        //     toast.error('Poll Duration can be between 30 days to 3 months for registered users')
        // }

        // else if(usertype === 'T'){
        //   if (pollData.duration_type === 'd'){
        //     if (pollData.duration < 21 || pollData.duration > 180)
        //       invalid = true
        //   }
        //   else {
        //     if (pollData.duration < 1 || pollData.duration > 6)
        //       invalid = true
        //   }

        //   if(invalid)
        //     toast.error('Poll Duration can be between 21 days to 6 months for trusted users')
        // }

        // else {
        //   if (pollData.duration_type === 'd'){
        //     if (pollData.duration < 14 || pollData.duration > 270)
        //       invalid = true
        //   }
        //   else {
        //     if (pollData.duration < 1 || pollData.duration > 9)
        //       invalid = true
        //   }

        //   if(invalid)
        //     toast.error('Poll Duration can be between 14 days to 9 months for authenticated users')
        // }

        // if (invalid)
        //   return

        if (!pollData.duration || !pollData.name || !pollData.category || !pollData.questionType || !pollData.scale || !pollData.questions.every(q => q.text)) {
          toast.error('Please fill in all fields before proceeding to the next step.');
          return;
        }
      }
      setCurrentStep(currentStep + 1);
      window.scrollTo({
        top: 200
      });
    } else {
      if (isloading) return
      if (id !== -1) {
        navigate('/home')
        return
      }
      setIsLoading(true)
      try {
        const response = await axios.post('https://www.app.glocalpolls.com/api/create-poll/', {
          token: localStorage.getItem('token'),
          ...pollData
        }, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        toast.success('Successfully created poll')
        localStorage.setItem(userpoll, '')
        setId(response.data['poll'])
      } catch (error) {
        toast.error('Error creating poll');
      }
      setIsLoading(false)
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      window.scrollTo({
        top: 200
      });
    }
  };

  // question adding removing
  const handleQuestionChange = (index, event) => {
    const newQuestions = [...pollData.questions];
    newQuestions[index].text = event.target.value;
    setPollData({ ...pollData, questions: newQuestions })
    localStorage.setItem(userpoll, JSON.stringify({ ...pollData, questions: newQuestions }));
  };

  const handleQuestionImageChange = (index, event) => {
    const newQuestions = [...pollData.questions];
    const file = event.target.files[0];

    if (file) {
      const allowedExtensions = [".png", ".jpg", ".jpeg"];
      const extension = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();

      if (allowedExtensions.includes(extension)) {
        const reader = new FileReader();
        reader.onloadend = () => {
          newQuestions[index].image = file;
          setPollData({ ...pollData, questions: newQuestions })
          localStorage.setItem(userpoll, JSON.stringify({ ...pollData, questions: newQuestions }));
        };
        reader.readAsDataURL(file);
      } else {
        toast.error("Invalid file type. Please upload a PNG, JPG, or JPEG file.");
      }
    }
  };

  const handleUserAllowedChange = (event) => {
    const value = event.target.value;
    var updatedValue = '';
    if (pollData.usersAllowed.includes(value))
      updatedValue = pollData.usersAllowed.replace(value, '');
    else
      updatedValue = pollData.usersAllowed + value;
    setPollData({ ...pollData, usersAllowed: updatedValue });
    localStorage.setItem(userpoll, JSON.stringify({ ...pollData, usersAllowed: updatedValue }));
  };

  const handleAddQuestion = () => {
    if (pollData.questions.length < 10) {
      setPollData({ ...pollData, questions: [...pollData.questions, { text: "", image: null }] });
      localStorage.setItem(userpoll, JSON.stringify({ ...pollData, questions: [...pollData.questions, { text: "", image: null }] }));
    }
  };

  const handleRemoveQuestion = (index) => {
    const newQuestions = [...pollData.questions];
    newQuestions.splice(index, 1);
    setPollData({ ...pollData, questions: newQuestions });
    localStorage.setItem(userpoll, JSON.stringify({ ...pollData, questions: newQuestions }));
  };

  function downloadQRCode() {
    const qrCodeImage = document.getElementById('qrCodeImage');

    if (qrCodeImage) {
      const margin = 20; // Adjust the margin as needed
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const width = qrCodeImage.width + 2 * margin;
      const height = qrCodeImage.height + 2 * margin;

      canvas.width = width;
      canvas.height = height;

      // Add white padding
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, width, height);

      ctx.drawImage(qrCodeImage, margin, margin);

      const dataUrl = canvas.toDataURL('image/png');
      const a = document.createElement('a');
      a.href = dataUrl;
      a.download = 'qrcode.png';
      a.click();
    }
  }

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#2C6877',
      '&:hover': {
        backgroundColor: alpha('#2C6877', theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#2C6877',
    },
  }));

  const generatedUrl = () => {
    const url = new URL(window.location.href)
    return url.origin + '/view-poll/' + Encrypt(id, true);
  }

  // useEffect(() => { console.log(pollData) }, [pollData])

  function copyToClipboard() {
    navigator.clipboard.writeText(generatedUrl()).catch(() => { });
    toast.success('Copied to clipboard')
  }

  const incrementDuration = () => {
    setPollData((prevData) => {
      return { ...prevData, duration: prevData.duration < limits[usertype][1] ? prevData.duration + 1 : prevData.duration};
  });
  };

  // Function to decrement duration
  const decrementDuration = () => {
    setPollData((prevData) => {
      return { ...prevData, duration: prevData.duration > limits[usertype][0] ? prevData.duration - 1 : prevData.duration};
  });
  };

  // Function to handle increment when holding the button
  const handleIncrement = () => {
    incrementDuration()
    const timeid = setTimeout(() => {
      const id = setInterval(incrementDuration, 50); // Change the duration every 100 milliseconds
      setIntervalId(id)
    }, 1000)
    setTimeoutId(timeid)
  };

  // Function to handle decrement when holding the button
  const handleDecrement = () => {
    decrementDuration()
    const timeid = setTimeout(() => {
    const id = setInterval(decrementDuration, 50); // Change the duration every 100 milliseconds
    setIntervalId(id)
    },1000)
    setTimeoutId(timeid)
  };

  // Function to clear the interval when the button is released
  const clearIntervalId = () => {
    console.log('Ending',intervalId, timeoutId)
    clearTimeout(timeoutId)
    setTimeoutId(null)
      clearInterval(intervalId);
      setIntervalId(null)
    };
  

  return (
    <div className="container">
      <section className="multi-steps">
        <header>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3 className="text-center heading_color">Poll Creator</h3><br />
                <p className="text-center">
                  Created in two steps, simple polls provide you with swift and accurate results tailored to your needs, presented with simplicity.
                </p>
              </div>
            </div>
          </div>
        </header>

        <section className="mt-4">
          <div className="container">
            <form className="card">
              <div className="card-header">
                <nav className="nav nav-pills nav-fill step-nav">
                  <Link
                    className={`nav-link tab-pills ${currentStep === 0 ? "active" : ""
                      }`}
                  >
                    Poll Participants
                  </Link>
                  <Link
                    className={`nav-link tab-pills ${currentStep === 1 ? "active" : ""
                      }`}
                  >
                    Poll Parameters
                  </Link>
                  <Link
                    className={`nav-link tab-pills ${currentStep === 2 ? "active" : ""
                      }`}
                  >
                    Submit
                  </Link>
                </nav>
              </div>
              <div className="card-body">
                <div className={`tab ${currentStep === 0 ? "" : "d-none"}`}>
                  <div className="row p-5 step-row1">
                    {/* Step 1 content here */}
                    <div className="col-lg-6">
                      <div className="main-container">
                        <h2>Poll Visibility</h2>
                        <Tooltip placement="top-start" title={<Typography variant="caption" color="inherit" style={{ fontFamily: 'Montserrat' }}>
                          Good for private entities & institutions who wish to know/listen from their clients/customers.
                        </Typography>}>
                          <div className="radio-buttons">
                            <label className="custom-radio">
                              <input onChange={handleInputChange} value={true} required type="radio" name="visibility" checked={pollData.visibility === 'true'} />
                              <span className="radio-btn">
                                <i className="fi fi-sr-check-circle"></i>
                                <div className="hobbies-icon">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/public_poll.png"
                                    }
                                    alt="Public"
                                  />
                                  <h3 className="">Public</h3>
                                </div>
                              </span>
                            </label>
                            <label className="custom-radio">
                              <input onChange={handleInputChange} required value={false} type="radio" name="visibility" checked={pollData.visibility === 'false'} />
                              <span className="radio-btn">
                                <i className="fi fi-sr-check-circle"></i>
                                <div className="hobbies-icon">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/private_poll.png"
                                    }
                                    alt="Private"
                                  />
                                  <h3 className="">Private</h3>
                                </div>
                              </span>
                            </label>
                          </div>
                        </Tooltip>

                        <h2>Poll Results</h2>
                        <Tooltip placement="top-start" title={<Typography variant="caption" color="inherit" style={{ fontFamily: 'Montserrat' }}>
                          Good for schools, restaurants, garages, clubs, etc.
                        </Typography>}>
                          <div className="radio-buttons">
                            <label className="custom-radio">
                              <input onChange={handleInputChange} required value={true} type="radio" name="results" checked={pollData.results === 'true'} />
                              <span className="radio-btn">
                                <i className="fi fi-sr-check-circle"></i>
                                <div className="hobbies-icon">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/public_poll.png"
                                    }
                                    alt="Public"
                                  />
                                  <h3 className="">Public</h3>
                                </div>
                              </span>
                            </label>
                            <label className="custom-radio">
                              <input onChange={handleInputChange} required value={false} type="radio" name="results" checked={pollData.results === 'false'} />
                              <span className="radio-btn">
                                <i className="fi fi-sr-check-circle"></i>
                                <div className="hobbies-icon">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "assets/images/private_poll.png"
                                    }
                                    alt="Private"
                                  />
                                  <h3 className="">Private</h3>
                                </div>
                              </span>
                            </label>
                          </div>
                        </Tooltip>
                        <h2 className="text-center">Which user type can participate in your poll?</h2>
                        <div className="radio-buttons">
                          <label className="custom-radio">
                            <input onChange={handleUserAllowedChange} required type="checkbox" value='R' name="usersAllowed" checked={pollData.usersAllowed.includes('R')} />
                            <span className="radio-btn">
                              <i className="fi fi-sr-check-circle"></i>
                              <div className="hobbies-icon">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/public_poll.png"
                                  }
                                  alt="Registered"
                                />
                                <h3 className="">Registered</h3>
                              </div>
                            </span>
                          </label>
                          <label className="custom-radio">
                            <input onChange={handleUserAllowedChange} disabled={usertype === 'R'} required type="checkbox" value='T' name="usersAllowed" checked={pollData.usersAllowed.includes('T')} />
                            <span className="radio-btn" style={{ backgroundColor: usertype === 'R' && 'lightgray' }}>
                              <i className="fi fi-sr-check-circle"></i>
                              <div className="hobbies-icon">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "assets/images/private_poll.png"
                                  }
                                  alt="Trusted"
                                />
                                <h3 className="">Trusted</h3>
                              </div>
                            </span>
                          </label>
                          <label className="custom-radio">
                            <input onChange={handleUserAllowedChange} disabled={usertype !== 'A'} required type="checkbox" value='A' name="usersAllowed" checked={pollData.usersAllowed.includes('A')} />
                            <span className="radio-btn" style={{ backgroundColor: usertype !== 'A' && 'lightgray' }}>
                              <i className="fi fi-sr-check-circle"></i>
                              <i className="fi fi-sr-star"></i>
                              <div className="hobbies-icon">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "assets/images/private_poll.png"
                                  }
                                  alt="Authenticated"
                                />
                                <h3 className="">Authenticated</h3>
                              </div>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h2 className="text-center">Poll Reach</h2>
                      <div className="poll-reach">
                        <p>
                          As a <b>{summary.type[usertype]} user,</b> your poll can be joined by up to{" "}
                          <b>{summary.reach[usertype]} participants</b> across <b>Egypt</b>
                        </p>
                        <h6>Poll Reach : {summary.reach[usertype]} participants</h6>
                        <h6>Participants Type : {summary.visibility[pollData.visibility]} Participants</h6>
                        <hr />
                        <div className="private-note" style={{ opacity: pollData.visibility === 'false' ? 1 : 0.2 }}>
                          <h6>Private Poll</h6>
                          <p>
                            By making your poll private, only participants with the digital
                            invitation will be able to participate
                          </p>
                        </div>
                        <div className="public-note" style={{ opacity: pollData.visibility === 'true' ? 1 : 0.2 }}>
                          <h6>Public Poll</h6>
                          <p>
                            By making the results public everyone will be able to see the
                            results of your poll, including the private participants
                          </p>
                        </div>
                        <table className="table table-responsive table-bordered">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Poll Results</th>
                              <th>Poll Participants</th>
                              <th>Poll Reach</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>{summary.visibility[pollData.results]}</td>
                              <td>{summary.visibility[pollData.visibility]} participants</td>
                              <td>{summary.reach[usertype]} participants</td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="user-types">
                          <div className="registered-user" style={{ opacity: pollData.usersAllowed.includes('R') ? 1 : 0.2 }}>
                            <h6>Registered participants</h6>
                            <p>
                              Registered participants, register by email only. One
                              participant may have multiple emails, thus may impair the poll
                              results. Recommended for private poll only with limited
                              visibility
                            </p>
                          </div>
                          <div className="trusted user" style={{ opacity: pollData.usersAllowed.includes('T') ? 1 : 0.2 }}>
                            <h6>Trusted participants</h6>
                            <p>
                              Trusted Participants register using OTP via mobile operators
                              and are therefore less vulnerable to malicious polling
                              attempts. Thus, the poll results will be more reliable.
                            </p>
                          </div>
                          <div className="authenticated-user" style={{ opacity: pollData.usersAllowed.includes('A') ? 1 : 0.2 }}>
                            <h6>Authenticated participants</h6>
                            <p>
                              Authenticated participants require official document
                              verification and are the most reliable type of participant. By
                              selecting this type of participant, your poll will become 100%
                              authenticated, meaning the chances of having the same person
                              participate more than once or as multiple persons is greatly
                              diminished.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`tab ${currentStep === 1 ? "" : "d-none"}`}>
                  <div className="row p-5 step-row2">
                    {/* Step 2 content here */}
                    <div className="col-lg-6">
                      <div className="poll-name">
                        <div className="mb-4">
                          <label htmlFor="poll-name">Poll Name</label>
                          <input
                            type="text"
                            onChange={handleInputChange}
                            name="name"
                            placeholder="Poll Name"
                            className="form-control"
                            value={pollData.name}
                          />
                        </div>
                        <div className="mb-4 d-flex justify-content-between">
                          <label htmlFor="poll-duration">Poll Duration</label>
                          <div className="input-group button-group">
                            <button
                              className="btn btn-secondary"
                              onMouseDown={handleDecrement}
                              onMouseUp={clearIntervalId}
                              onMouseLeave={clearIntervalId}
                            >
                              -
                            </button>
                            <input
                              type="number"
                              name="duration"
                              placeholder="30"
                              className="form-control text-center"
                              value={pollData.duration}
                              disabled
                            />
                            <button
                              className="btn btn-secondary"
                              onMouseDown={handleIncrement}
                              onMouseUp={clearIntervalId}
                              onMouseLeave={clearIntervalId}
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <label htmlFor="category">Poll Category</label>
                        <select name="category" value={pollData.category} id="category" className="form-control mb-4" onChange={handleInputChange}>
                          <option value='s'>Sports</option>
                          <option value='p'>Politics</option>
                          <option value='e'>Education</option>
                          <option value='n'>News</option>
                          <option value='a'>Animals</option>
                          <option value='f'>Food</option>
                        </select>
                        <div className="poll-image">
                          <label htmlFor="poll-name">Poll Image</label>
                          <div className="avatar-upload">
                            <div className="avatar-edit">
                              <input
                                type="file"
                                id="imageUpload"
                                accept=".png, .jpg, .jpeg"
                                onChange={handleImageChange}
                              // value={pollData.image}
                              />
                              <label htmlFor="imageUpload">
                                <i className="fi fi-sr-images"></i>
                              </label>
                            </div>
                            <div className="avatar-preview">
                              <div
                                key={imagePreview}
                                id="imagePreview"
                                style={{ backgroundImage: `url(${imagePreview})` }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <p>please select one option </p>
                      <label className="custom-radio">
                        <input onChange={handleInputChange} value={1} type="radio" name="questionType" checked={pollData.questionType == '1'} />
                        <span className="radio-btn">
                          <i className="fi fi-sr-check-circle"></i>

                          <div className="hobbies-icon">
                            <img
                              src={
                                process.env.PUBLIC_URL + "assets/images/private_poll.png"
                              }
                              alt="Single Choice"
                            />
                            <h3 className="">Single Choice</h3>
                          </div>
                        </span>
                      </label>
                      <label className="custom-radio">
                        <input onChange={handleInputChange} value={2} type="radio" name="questionType" checked={pollData.questionType == '2'} />
                        <span className="radio-btn">
                          <i className="fi fi-sr-check-circle"></i>

                          <div className="hobbies-icon">
                            <img
                              src={
                                process.env.PUBLIC_URL + "assets/images/private_poll.png"
                              }
                              alt="Multiple Choice"
                            />
                            <h3 className="">Multiple Choice</h3>
                          </div>
                        </span>
                      </label>

                      <div className="row">
                        <div className="single-choice">
                          <p>please select one option</p>
                          <label className="custom-radio">
                            <input onChange={handleInputChange} value={1} type="radio" name="scale" checked={pollData.scale == '1'} />
                            <span className="radio-btn">
                              <i className="fi fi-sr-check-circle"></i>

                              <div className="hobbies-icon">
                                <h3 className="">Yes/No/Maybe</h3>
                              </div>
                            </span>
                          </label>
                          <label className="custom-radio">
                            <input onChange={handleInputChange} value={2} type="radio" name="scale" checked={pollData.scale == '2'} />
                            <span className="radio-btn">
                              <i className="fi fi-sr-check-circle"></i>

                              <div className="hobbies-icon">
                                <h3 className="">1-5 scale</h3>
                              </div>
                            </span>
                          </label>
                        </div>
                        <div className="multiple-choice">

                          <label className="custom-radio">
                            <input onChange={handleInputChange} value={3} type="radio" name="scale" checked={pollData.scale == '3'} />
                            <span className="radio-btn">
                              <i className="fi fi-sr-check-circle"></i>

                              <div className="hobbies-icon">
                                <h3 className="">Likely/Neutral/Not Likely</h3>
                              </div>
                            </span>
                          </label>
                          <label className="custom-radio">
                            <input onChange={handleInputChange} value={4} type="radio" name="scale" checked={pollData.scale == '4'} />
                            <span className="radio-btn">
                              <i className="fi fi-sr-check-circle"></i>

                              <div className="hobbies-icon">
                                <h3 className="">1-10 scale</h3>
                              </div>
                            </span>
                          </label>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                          <h5 className="question-title-h5">Add Question
                            <div className="d-flex align-items-center">
                              <FormControlLabel
                                value="start"
                                control={<CustomSwitch checked={pollData.top5} onChange={handleTop5Change} />}
                                label="Top 5"
                                labelPlacement="start"
                                className="me-2"
                              />
                              {pollData.questions.length < 10 && (
                                <i
                                  className="fi fi-sr-square-plus add-question-row pt-2"
                                  onClick={handleAddQuestion}
                                ></i>
                              )}
                            </div>
                          </h5>

                          {pollData.questions.map((question, index) => (
                            <div className="question-row" key={index}>
                              <div className="question-div">
                                <input
                                  type="text"
                                  name="question"
                                  placeholder="Enter Question"
                                  className="form-control"
                                  value={question.text}
                                  onChange={(event) => handleQuestionChange(index, event)}
                                />
                                <input
                                  type="file"
                                  accept=".png, .jpg, .jpeg"
                                  name="question-image"
                                  className="form-control"
                                  // ref={fileInputRefs[index]}
                                  onChange={(event) =>
                                    handleQuestionImageChange(index, event)
                                  }
                                // value={question.image}
                                />
                                {pollData.questions.length > 1 && (
                                  <i
                                    className="fi fi-sr-minus remove-question-row"
                                    onClick={() => handleRemoveQuestion(index)}
                                  ></i>
                                )}
                              </div>
                            </div>
                          ))}


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`tab ${currentStep === 2 ? "" : "d-none"}`}>
                  <div className="row p-5 step-row3">
                    {/* Step 3 content here */}
                    <div className="col-lg-4">
                      {id === -1 ?
                        <div className="d-flex flex-column align-items-center justify-content-center h-100">
                          {isloading && <>
                            <p>Your QR Code is being generated.</p>
                            <p>Please wait!</p>
                          </>}
                        </div> :
                        <>
                          <div className="created_qr">
                            <p>
                              Your QR Code has been created. Click to download. Share it, and open new horizons.
                            </p>
                            <div className="qr-code-img shadow-lg mb-4" onClick={downloadQRCode}>
                              <QRCode id="qrCodeImage" value={generatedUrl()} size={200} />
                            </div>
                            <div className="poll-link-div">
                              <span className="poll_link">
                                {generatedUrl()}
                              </span>
                              <span className="copy-icon" onClick={copyToClipboard}>
                                <i className="fi fi-sr-copy-alt"></i>
                              </span>
                            </div>
                          </div>
                        </>}

                    </div>
                    <div className="col-lg-8">
                      <div>
                        <h4 className="heading_color">Poll summary</h4>
                        <p>
                          {summary.type[usertype]} User Poll summary: The poll will reach a maximum of {summary.reach[usertype]} {' '}
                          participants free of charge, via a private invitation link. The
                          poll results will{pollData.results === 'false' && ' not'} be available to the public.
                        </p>
                        <p>
                          Participants can select {pollData.questionType === '2' ? 'more than' : 'only'} one choice. Participant types
                          are limited to {userTypeDescription()} users giving your poll good
                          integrity and less chance of false polls.{" "}
                        </p>
                        <p>
                          The poll is a {pollData.questionType === '2' ? 'Multiple' : 'Single'} Choice Poll with {pollData.scale % 2 === 1 ? 'Qualitative' : 'Numeric'} grading. The poll
                          has {pollData.questions.length} question{pollData.questions.length > 1 && 's'} which will be shown in the
                          results and it will run for up to {pollData.duration} days. History mode is enabled
                          for previous months' values
                        </p>
                        <h5 className="poll_note">
                          It may take up to <b>2 working days</b> to review and publish your
                          polls.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer text-end">
                <div className="d-flex">
                  <button
                    type="button"
                    id="back_button"
                    className="btn btn-link btn-secondary back-button"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    id="next_button"
                    className="btn btn-primary ms-auto next-button"
                    onClick={handleNext}
                  >
                    {currentStep < 2 ? "Next" : id !== -1 ? "Exit" : 'Submit'}
                    <ClipLoader className='ms-2' loading={isloading} color='white' size={20} />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </section>
      </section>
    </div>
  );
}

export default MultiSteps;
