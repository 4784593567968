/* eslint-disable react/jsx-no-undef */

import React, { useEffect, useState } from "react";
import "charts.css";
import "./css/Welcome.css";

// import component
import AnimationCss from "../Components/Welcome-animation";
import { useNavigate } from "react-router-dom";

function WelcomeScreen() {
  const [showCol5, setShowCol5] = useState(false);
  const navigate = useNavigate();
  const year = new Date().getFullYear();

  useEffect(() => {
    // Add the fade-in class after 2 seconds
    const timer = setTimeout(() => {
      const taglineElement = document.querySelector(".tagline-");
      if (taglineElement) {
        taglineElement.classList.add("tagline-fade-in");
      }

      setTimeout(() => {
        setShowCol5(true);

        setTimeout(() => {
          navigate('/home')
        }, 3000)
      }, 1200);
    }, 2100);

    // Clean up the timer to avoid memory leaks
    return () => clearTimeout(timer);
  }, []);



  return (
    <div className={`welcome-screen ${showCol5 ? "show-row" : ""}`}>
      <div className="container">
        <div className="row switch-row ">
          <div className={`col-lg-12  ${showCol5 ? "mtop" : ""} text-center`}>
            <div className="txt-div ">
              <div className="text-container">
                <span className="letter">G</span>
                <span className="letter">L</span>
                <span className="letter">O</span>
                <span className="letter">C</span>
                <span className="letter">A</span>
                <span className="letter">L</span>
                <span className="letter">P</span>
                <span className="letter">O</span>
                <span className="letter">L</span>
                <span className="letter">L</span>
                <span className="letter">S</span>
              </div>
              <p className="tagline-">The People’s heartbeat</p>
            </div>
          </div>

          <div className="col-lg-12">
            <div id="animations-example-3">
              {showCol5 && (
                <table className="charts-css column show-labels hide-data data-spacing-5 show-primary-axis tbl-cls">
                  <caption>Animation Example #3</caption>
                  <thead>
                    <tr>
                      <th scope="col">Year</th>
                      <th scope="col">Progress</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">{year-4}</th>
                      <td style={{ "--size": 0.2 }}>
                        <span className="data">20</span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{year-3}</th>
                      <td style={{ "--size": 0.4, opacity:0.9 }}>
                        <span className="data">40</span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{year-2}</th>
                      <td style={{ "--size": 0.6, opacity:0.7  }}>
                        <span className="data">60</span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{year-1}</th>
                      <td style={{ "--size": 0.8, opacity:0.6  }}>
                        <span className="data">80</span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{year}</th>
                      <td style={{ "--size": 1, opacity:0.5  }}>
                        <span className="data">100</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WelcomeScreen;
