import React, { useEffect } from 'react';

function About() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, [])
  return (
    <div>
      <div className="page-banner">
        <h1 className="page-title">About Us</h1>
        <div className="overlay"></div>
      </div>

      <div className="page-content">
        <div className="container">
          <div className="row about-us-row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <h3>Something about us</h3>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet corporis illo, pariatur ipsa aut nostrum sint sunt recusandae! Dolores blanditiis quos fugiat debitis explicabo qui. Perspiciatis similique ad est culpa!
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <img src="https://images.pexels.com/photos/460672/pexels-photo-460672.jpeg?auto=compress&cs=tinysrgb&w=800" className="about-us-img shadow-sm" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
