import React, { useEffect, useState } from 'react';
import ScanSearchComponent from '../Components/Scan-search';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';


function HomePage() {
  const user = localStorage.getItem('token');
  const [loggedIn, setloggedIn] = useState(false)
  const [total, setTotal] = useState(0)
  const [now, setNow] = useState(0)
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, [])
  

  function formatNumber(number) {
    if (number >= 1000000) {
      return (number / 1000000).toFixed(1) + ' M';
    } else if (number >= 1000) {
      return (number / 1000).toFixed(1) + ' K';
    } else {
      return number.toString();
    }
  }

  async function fetchUser() {
    await axios.post('https://www.app.glocalpolls.com/api/get-user/', { 
      token: user 
    }).then((response) => {
      setloggedIn(true)
      setTotal(response.data['total'])
      setNow(response.data['polling'])
    }).catch((error) => {
      localStorage.removeItem('token')
      setTotal(error.response.data['total'])
      setNow(error.response.data['polling'])
    })
  }
  if (!loggedIn) {
    fetchUser();
  }
  
  useEffect(() => {

    async function updateType(amount) {
      await axios.post('https://www.app.glocalpolls.com/api/update-type/', {
        token: localStorage.getItem('token'),
        type: amount === '2499' ? 'T' : 'A'
      }).then((response) => {
        toast.success('Successfully updated user type')
        navigate('/home')
      }).catch((error) => {
        toast.error('Error occurred while updating')
      })
    }

    const urlParams = new URLSearchParams(window.location.search);

    const success = urlParams.get('success');
    const amountCents = urlParams.get('amount_cents');

    if (success === 'true') {
      updateType(amountCents)
    } else if (success === 'false') {
      toast.error('Error occurred while updating')
      navigate('/home')
    }
  }, []);


  return (
    <div className="container pt-5 pb-5">

      <div className="row mb-3 mt-3">
        <div className="col-lg-12 text-center">
          <h4 className="text-center">{formatNumber(total)} Users - {formatNumber(now)} POLLING NOW</h4><br/>
          <p>The world's first global local polling platform welcomes you. Your identity is one of your most valuable assets.<br/> Use it wisely and if in doubt anonymize it, its your right!</p>
          <p>!إن منصة الاقتراع المحلية العالمية الأولى في العالم ترحب بكم. هويتك هي واحدة من الأصول الأكثر قيمة لديك. استخدمه بحكمة، وإذا كنت في شك، قم بإخفاء هويتك، فهذا حقك</p>
        </div>
      </div>



      <div className="index-page row mt-4 mb-4">
        <div className="col-lg-4 col-md-6 mb-4">
          <div className="card simple_poll shadow-sm">
            <div className='d-flex justify-content-center'>
              <img src={process.env.PUBLIC_URL + '/assets/images/polling.jpg'} draggable={false} className="card-img-top" alt="My Image" />
            </div>

            <div className="card-body">
              <h5 className="card-title">Simple poll</h5>
              <p className="card-text" style={{fontSize:14, opacity:0.7}}>Vote for your board’s election (private mode available), know your customers preference, discover the latest trends from friends</p>
              <div className="d-grid gap-2">
                {loggedIn ? (
                  <>
                    <Link className="btn create-poll" to="/create-poll" type="button">Create Simple Poll</Link>
                    <Link className="btn btn-secondary view-poll" to="/polls" type="button">View Simple Polls</Link>
                  </>
                ) : (
                  <>
                  <button className="btn create-poll" data-bs-toggle="modal" data-bs-target="#registerModal" type="button">Create Simple Poll</button>
                  <button className="btn btn-secondary view-poll" data-bs-toggle="modal" data-bs-target="#registerModal" type="button">View Simple Polls</button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 mb-4">
          <div className="card simple_poll">
            <div className='d-flex justify-content-center'>
              <img src={process.env.PUBLIC_URL + '/assets/images/survey.jpg'} draggable={false} className="card-img-top" alt="My Image" />
            </div>            <div className="card-body">
              <h5 className="card-title">Survey</h5>
              <p style={{fontSize:14, opacity:0.7}}>coming soon...</p>
            </div>
          </div>
        </div>

        <div className="col-lg-4">
          <div className="card simple_poll">
          <div className='d-flex justify-content-center'>
              <img src={process.env.PUBLIC_URL + '/assets/images/review.jpg'} draggable={false} className="card-img-top" alt="My Image" />
            </div>
            <div className="card-body">
              <h5 className="card-title">Review</h5>
              <p style={{fontSize:14, opacity:0.7}}>coming soon...</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
