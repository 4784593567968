import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from 'react-spinners'
import Slider from '@mui/material/Slider';
import { toast } from "react-toastify";
import { Decrypt } from "../Components/Helper";
import { ThemeProvider, createTheme } from "@mui/material";
import Chart from 'chart.js/auto';
import Highcharts from 'highcharts/highmaps';

const theme = createTheme({
  palette: {
    // mode: 'dark',
    primary: {
      main: 'rgb(255, 255, 255)',
    },
    secondary: {
      main: '#2C6877',
    }
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  }
});

const array = Array.from({ length: 10 }, (_, index) => ({
  value: (index + 1),
  label: String(index + 1),
}));


function Answers(props) {
  const answer_count = { 1: 3, 2: 5, 3: 3, 4: 10 }
  const answers = { 1: ['Yes', 'No', 'Maybe'], 3: ['Likely', 'Neutral', 'Not Likely'] }
  return (
    <>
      {props.scale % 2 == 0 ? (

        <ThemeProvider theme={theme}>
          <Slider
            aria-label="Temperature"
            valueLabelDisplay="off"
            marks={array}
            value={props.value}
            min={0}
            color="secondary"
            max={answer_count[props.scale]}
            onChange={(e) => props.addAnswer(props.id, e.target.value)}
          />
        </ThemeProvider>
      ) : (
        <>
          {props.ans.hasOwnProperty(props.id) ?
            <div className="poll-engagements">
              {Array.from({ length: answer_count[props.scale] }).map((_, index) =>
                <label htmlFor={index} key={index}>
                  <input
                    type="radio"
                    className=""
                    name={props.type === 1 ? 'poll-value' : `poll-value${props.id}`}
                    checked={index + 1 === props.ans[props.id]}
                    readOnly
                  />
                  <span>{props.scale % 2 === 1 ? answers[props.scale][index] : index + 1}</span>
                </label>
              )}
            </div>
            :
            <div className="poll-engagements">
              {Array.from({ length: answer_count[props.scale] }).map((_, index) =>
                <label htmlFor={index} key={index}>
                  <input
                    type="radio"
                    className=""
                    value={index + 1}
                    onChange={(e) => props.addAnswer(props.id, index + 1)}
                    name={props.type === 1 ? 'poll-value' : `poll-value${props.id}`}
                  />
                  <span>{props.scale % 2 === 1 ? answers[props.scale][index] : index + 1}</span>
                </label>
              )}
            </div>
          }
        </>
      )}
    </>
  )
}

const PollResult = () => {
  const { id, allowed } = Decrypt();
  const [pollData, setPollData] = useState({})
  const [questions, setQuestions] = useState([])
  const [answers, setAnswers] = useState([])
  const [isloading, setIsLoading] = useState(false)
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const colors = ["#4fda4f", "#fb5151", "grey"]
  const [votes, setVotes] = useState(0)
  const [period, setPeriod] = useState(7);
  const [dates, setDates] = useState([]);
  const [voted, setVoted] = useState(false)
  const [answered, setAnswered] = useState({})
  const [voteareas, setVoteAreas] = useState({})
  const [stats, setStats] = useState([])
  const [showResults, setShowResults] = useState(false)
  const [usertype, setUserType] = useState('')
  const areas = {
    'eg-an': "Aswan", 'eg-at': "Asyut", 'eg-ba': "Al Bahr al Ahmar", 'eg-bh': "Al Buhayrah"
    , 'eg-bn': "Bani Suwayf", 'eg-bs': "Bur Sa`id", 'eg-dq': "Ad Daqahliyah", 'eg-dt': "Dumyat"
    , 'eg-fy': "Al Fayyum", 'eg-gh': "Al Gharbiyah", 'eg-ik': "Al Iskandariyah", 'eg-is': "Al Isma`iliyah"
    , 'eg-js': "Janub Sina'", 'eg-jz': "Al Jizah", 'eg-ks': "Kafr ash Shaykh", 'eg-mf': "Al Minufiyah"
    , 'eg-mn': "Al Minya", 'eg-mt': "Matruh", 'eg-qh': "Al Qahirah", 'eg-ql': "Al Qalyubiyah"
    , 'eg-qn': "Qina", 'eg-sj': "Suhaj", 'eg-sq': "Ash Sharqiyah", 'eg-ss': "Shamal Sina'"
    , 'eg-sw': "As Suways", 'eg-uq': "Luxor", 'eg-wj': "Al Wadi at Jadid"
  }
  const type = {
    'R': 'Registered',
    'T': 'Trusted',
    'A': 'Authenticated'
  }
  const answersTypes = { 1: ['Yes', 'No', 'Maybe'], 3: ['Likely', 'Neutral', 'Not Likely'] }
  const duration = {
    'R': 7,
    'T': 15,
    'A': 30
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, [])

  useEffect(() => {
    async function increment() {
      try {
        const response = await axios.get('https://www.app.glocalpolls.com/api/update-count/');
      } catch (error) {
      }
    }

    increment();

    // Cleanup function
    const cleanup = async () => {
      document.querySelector('#qrCodeModal .btn-close')?.click();
      try {
        const response = await axios.post('https://www.app.glocalpolls.com/api/update-count/');
      } catch (error) {
      }
    };

    window.addEventListener('beforeunload', cleanup);

    return () => {
      window.removeEventListener('beforeunload', cleanup);
      cleanup();
    };
  }, []);

  useEffect(() => {
    Object.entries(stats).forEach(([question, answerPercentages], index) => {
      const chartId = `pieChart${index}`;
      const chartCanvas = document.getElementById(chartId);
      Chart.getChart(chartCanvas)?.destroy();

      if (chartCanvas) {
        const ctx = chartCanvas.getContext('2d');
        new Chart(ctx, {
          type: 'pie',
          data: {
            labels: pollData.scale % 2 == 0 ? Object.keys(answerPercentages) : answersTypes[pollData.scale],
            datasets: [
              {
                data: Object.values(answerPercentages),
                backgroundColor: pollData.scale % 2 === 1 ? ["#4fda4f", "#fb5151", "grey"] : [
                  '#FF6384', // Red
                  '#36A2EB', // Blue
                  '#FFCE56', // Yellow
                  '#4BC0C0', // Turquoise
                  '#9966CC', // Lavender
                  '#32CD32', // Lime Green
                  '#FF4500', // Orange Red
                  '#1E90FF', // Dodger Blue
                  '#FF69B4', // Hot Pink
                  '#00FF7F', // Spring Green
                ],
              },
            ],
          },
          options: {
            title: {
              display: true,
              text: question,
            },

          },
        });
      }
    });
  }, [showResults]);

  useEffect(() => {
    if (usertype !== 'R' && showResults) {
      let timePeriod;
      if (period === '12') {
        timePeriod = Array.from({ length: 12 }, (_, i) => {
          const date = new Date();
          date.setMonth(date.getMonth() - 11 + i);
          return date.toISOString().slice(0, 7); // Keep only the year and month
        });
      }
      else {
        timePeriod = Array.from({ length: period }, (_, i) => {
          const date = new Date();
          date.setDate(date.getDate() - period + i + 1);
          return date.toISOString().split('T')[0];
        });
      }
      const initialDictionary = timePeriod.reduce((dict, date) => {
        dict[date] = 0;
        return dict;
      }, {});

      // Count occurrences of each date in the dates array
      const dateCounts = dates.reduce((counts, date) => {
        const formattedDate = new Date(date).toISOString().split('T')[0];
        const key = period === '12' ? formattedDate.slice(0, 7) : formattedDate; // Keep only the year and month for 12 months
        if (counts.hasOwnProperty(key)) {
          counts[key] += 1;
        }
        return counts;
      }, initialDictionary);

      const viewsChartCanvas = document.getElementById('viewsChart');
      Chart.getChart(viewsChartCanvas)?.destroy();

      // Create a new chart with proper configuration
      const ctx = viewsChartCanvas.getContext('2d');
      new Chart(ctx, {
        type: 'line',
        data: {
          labels: timePeriod,
          datasets: [
            {
              label: 'Number of Votes',
              data: dateCounts,
              fill: false,
              borderColor: 'rgba(75,192,192,1)', // You can customize the color
            },
          ],
        },
        options: {
          scales: {
            x: {
              type: 'category',
              labels: timePeriod,
            },
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    }
  }, [showResults, period]);

  useEffect(() => {
    async function heatmap() {
      const topology = await fetch(
        'https://code.highcharts.com/mapdata/countries/eg/eg-all.topo.json'
      ).then(response => response.json());

      // Prepare demo data. The data is joined to map using value of 'hc-key'
      // property by default. See API docs for 'joinBy' for more info on linking
      // data and map.

      let data = [
        ['eg-5847', 0], ['eg-ba', 0], ['eg-js', 0], ['eg-uq', 0],
        ['eg-is', 0], ['eg-gh', 0], ['eg-mf', 0], ['eg-qh', 0],
        ['eg-ql', 0], ['eg-sq', 0], ['eg-ss', 0], ['eg-sw', 0],
        ['eg-dq', 0], ['eg-bs', 0], ['eg-dt', 0], ['eg-bh', 0],
        ['eg-mt', 0], ['eg-ik', 0], ['eg-jz', 0], ['eg-fy', 0],
        ['eg-wj', 0], ['eg-mn', 0], ['eg-bn', 0], ['eg-ks', 0],
        ['eg-at', 0], ['eg-an', 0], ['eg-qn', 0], ['eg-sj', 0]
      ];

      for (let i = 0; i < data.length; i++) {
        if (voteareas.hasOwnProperty(data[i][0]))
          data[i][1] = voteareas[data[i][0]]
      }

      // Create the chart
      Highcharts.mapChart('container', {
        chart: {
          map: topology
        },

        title: {
          text: 'Voter Distribution',
          style: {
            fontFamily: 'Montserrat'
          }
        },

        subtitle: {
          // text: 'Source map: <a href="http://code.highcharts.com/mapdata/countries/eg/eg-all.topo.json">Egypt</a>'
        },

        mapNavigation: {
          enabled: true,
          buttonOptions: {
            verticalAlign: 'bottom'
          }
        },

        colorAxis: {
          min: 0,
          stops: [
            [0, '#fff'],
            [1, '#14aaab']
          ]
        },

        series: [{
          data: data,
          name: 'Number of votes',
          dataLabels: {
            enabled: true,
            format: '{point.name}',
            style: {
              fontFamily: 'Montserrat',
              fontWeight: 400,
            }
          }
        }]
      });
    }

    if (usertype === 'A' && showResults)
      heatmap()
  }, [showResults])

  useEffect(() => {
    async function getPoll() {
      await axios.get('https://www.app.glocalpolls.com/api/vote-poll/', {
        params: {
          poll_id: id,
          token: localStorage.getItem('token')
        }
      }).then((response) => {
        setStats(response.data['stats'])
        setName(response.data['username'])
        setVotes(response.data['votes'])
        setPollData(response.data['pollData'])
        setVoted(response.data['voted'])
        setVoteAreas(response.data['areas'])
        setAnswered(response.data['answered'])
        setDates(response.data['dates'])
        const utype = response.data['usertype'];
        setUserType(utype)
        if (!response.data['pollData'].users_allowed.includes(utype) && !response.data['voted'])
          toast.error(type[utype] + ' users cannot participate in this poll')
        var pollEndTime = new Date(response.data['pollData'].created)
        pollEndTime.setDate(pollEndTime.getDate() + response.data['pollData'].duration);
        updateCountdown(pollEndTime.getTime());
        setQuestions(response.data['questions'])
      }).catch((error) => {
        toast.error('You do not have access to this poll!')
        navigate('/home')
      })
    }
    getPoll()
  }, [])

  function updateCountdown(endTime) {
    // Get the element with class "time_remaining"
    var countdownElement = document.querySelector('.time_remaining');
    var totalRemainingSeconds = Math.floor((endTime - Date.now()) / 1000);

    // Update the timer every 1 second
    var countdownInterval = setInterval(function () {
        if (totalRemainingSeconds <= 0) {
            // If the countdown is complete, display a message or take any other action
            countdownElement.textContent = 'Poll Expired!';
            clearInterval(countdownInterval); // Stop the timer 
        } else {
            // Calculate days, hours, minutes, and seconds
            var remainingDays = Math.floor(totalRemainingSeconds / (3600 * 24));
            var remainingHours = Math.floor((totalRemainingSeconds % (3600 * 24)) / 3600);
            var remainingMinutes = Math.floor((totalRemainingSeconds % 3600) / 60);
            var remainingSeconds = totalRemainingSeconds % 60;

            // Format the time to include leading zeros
            var formattedTime =
                (remainingDays > 0 ? remainingDays + 'd ' : '') +
                (remainingHours < 10 ? '0' : '') + remainingHours + 'h:' +
                (remainingMinutes < 10 ? '0' : '') + remainingMinutes + 'm:' +
                (remainingSeconds < 10 ? '0' : '') + remainingSeconds + 's';

            countdownElement.textContent = formattedTime;

            // Subtract 1 second from the remaining time
            totalRemainingSeconds -= 1;
        }
    }, 1000); // Update every 1 second (1000 milliseconds)
}


  const handleAnswerSelection = (ques, ans) => {
    if (pollData['question_type'] === 1) {
      setAnswers([{ question: ques, answer: ans }])
    }
    else {
      const updatedAnswers = [...answers];
      const existingAnswerIndex = updatedAnswers.findIndex((entry) => entry.question === ques);

      if (existingAnswerIndex !== -1) {
        // If the question already exists, replace the answer
        updatedAnswers[existingAnswerIndex] = { question: ques, answer: ans };
      } else {
        // If the question doesn't exist, add a new entry
        updatedAnswers.push({ question: ques, answer: ans });
      }

      setAnswers(updatedAnswers);
    }
  };

  function copyToClipboard() {
    navigator.clipboard.writeText(window.location.href).catch(() => { });
    toast.success('Copied to clipboard')
  }

  async function submitPoll() {
    if (!pollData.users_allowed.includes(usertype)) {
      toast.error(type[usertype] + ' users cannot participate in this poll')
      return
    }
    setIsLoading(true)
    await axios.post('https://www.app.glocalpolls.com/api/vote-poll/', {
      poll: pollData.id,
      token: localStorage.getItem('token'),
      answers: answers
    }).then((response) => {
      toast.success('Successfully submitted poll!')
      navigate('/polls')
    }).catch((response) => {
      toast.error('Error submitting poll')
    })
    setIsLoading(false)
  }

  const getValue = (quesId) => {
    for (let i = 0; i < answers.length; i++) {
      if (answers[i]['question'] === quesId)
        return answers[i]['answer']
    }
    return 0
  }

  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);
    return date.toLocaleDateString("en-GB").replace(/\//g, '-');
  };

  const expiryDate = (created) => {
    const newDate = new Date(created);
    newDate.setDate(newDate.getDate() + pollData.duration);
    return newDate.toLocaleDateString("en-GB").replace(/\//g, '-');;
  }

  const sortedStats = Object.entries(stats).sort(([, valuesA], [, valuesB]) => {
    const sumA = Object.values(valuesA).reduce((acc, val) => acc + val, 0);
    const sumB = Object.values(valuesB).reduce((acc, val) => acc + val, 0);

    return sumB - sumA;
  });

  const sortedStatsObject = Object.fromEntries(sortedStats);
  // console.log(sortedStatsObject)

  return (
    <div className="container pt-5 pb-5">
      <section className="poll-result-section poll-sign-page">
        <div className="row">
          <div className="col-lg-6 pol-res-col">
            <div className="bnt-div">
              <h3 className="poll-title">{pollData.name}</h3>
              {(pollData.results || allowed) &&
                <button onClick={() => setShowResults(!showResults)} className="btn submit_button">{showResults ? 'Hide' : 'Show'} Results</button>
              }
            </div>
            <p className="poll-description">This poll was created by {name}, {votes} people have voted this.</p>
            <button className="copy_poll" onClick={copyToClipboard}>
              <i className="fi fi-sr-copy-alt me-2"></i> Get poll link
            </button>
            <button className="copy_poll me-2" data-bs-toggle="modal" data-bs-target="#qrCodeModal">
              <i className="fi fi-sr-copy-alt me-2"></i> Get QR Code
            </button>
            <div className="dates">
              <p className="created_at">
                Start: <span>{formatDate(pollData.created)}</span>
              </p>
              <p className="created_expire">
                End: <span>{expiryDate(pollData.created)}</span>
              </p>
              <p className="time_remaining"></p>
            </div>

            {showResults ?
              <>
                {Object.entries(sortedStatsObject).map(([question, answerPercentages], index) => (
                  <div className='reslts' key={index}>
                    <p>{question}</p>
                    {Object.entries(answerPercentages).map(([answer, percentage], ans_index) => (
                      <>
                        {(percentage !== 0 || pollData.scale % 2 === 1) &&
                          <div className='progress' key={ans_index}>
                            <div
                              key={ans_index}
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: `${percentage}%`, backgroundColor: pollData.scale % 2 === 1 ? colors[ans_index] : 'rgb(13 110 253 / 70%)' }}
                              aria-valuenow={percentage}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                            </div>
                            <span className='vote-name'>{pollData.scale % 2 === 1 ? answersTypes[pollData.scale][ans_index] : answer}</span>
                            <span className='vote-num'>{`${parseInt(percentage)}%`}</span>
                          </div>
                        }
                      </>
                    ))}
                    <br />
                  </div>
                ))}
                <br /><br />
                <div className='row'>
                  {Object.entries(stats).map(([question, answerPercentages], index) => (
                    <div key={index} className='col-sm-6'>
                      <h4>{question}</h4>
                      <canvas id={`pieChart${index}`} width="100px" height="100px"></canvas>
                    </div>
                  ))}
                </div>

                {usertype !== 'R' && (
                  <>
                    <br /><br />
                    <div className="select-duration">
                      <h4>Voters</h4>
                      <select className="form-select" aria-label="Default select example" onChange={(e) => setPeriod(e.target.value)}>
                        <option value={7}>Last 7 days</option>
                        <option value={30}>Last 30 days</option>
                        <option value={12}>Last 12 months</option>
                      </select>
                    </div>
                    <canvas id="viewsChart"></canvas>
                  </>
                )}

                {usertype === 'A' && (
                  <>
                    <br /><br /><br />
                    <div id="container"></div>
                    <br /><br /><br />

                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Area</th>
                          <th>Votes</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(voteareas).map(([area, votes], index) => (
                          <tr key={index}>
                            <td>{areas[area]}</td>
                            <td>{votes}</td>
                          </tr>
                        ))}

                      </tbody>
                    </table>
                  </>
                )}
              </> :
              <>
                {questions.map((ques, index) => (
                  <div className="qes-div mb-4" key={index}>
                    {ques.image && <img className="mb-4 w-100" src={'https://www.app.glocalpolls.com/' + ques.image} />}
                    <h5> {index + 1}. {ques.text}</h5>
                    <Answers ans={answered} value={answered[ques.id]} scale={pollData.scale} id={ques.id} type={pollData.question_type} addAnswer={handleAnswerSelection} key={index} />
                  </div>
                ))}
                {(!voted && !pollData.expired) &&
                  <button className="btn btn-md submit_button sign-up" onClick={() => submitPoll()}>Submit
                    <ClipLoader className='ms-2' loading={isloading} color='white' size={20} />
                  </button>
                }
              </>}

          </div>
          <div className="col-lg-6 img-col">
            <img
              className="poll_image"
              src={pollData.image ? 'https://www.app.glocalpolls.com/' + pollData.image : process.env.PUBLIC_URL + '/assets/images/poll.jpg'}
              alt=""
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default PollResult;
