import React, { useEffect } from 'react';

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, [])
  return (
    <>
    <div>
      <div className="page-banner">
        <h1 className="page-title">Privacy Policy</h1>
        <div className="overlay"></div>
      </div>

      <div className="page-content">
        <div className="container">
          <h4>Privacy Policy Heading 1</h4>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Veniam, quod dolore. Nemo voluptate pariatur ab unde fugit veritatis, suscipit maiores esse officiis porro rem tenetur magnam laudantium veniam maxime nostrum?
          </p>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Veniam, quod dolore. Nemo voluptate pariatur ab unde fugit veritatis, suscipit maiores esse officiis porro rem tenetur magnam laudantium veniam maxime nostrum?
          </p>
          {/* Add more paragraphs as needed */}

          <h4>Privacy Policy Heading 2</h4>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Veniam, quod dolore. Nemo voluptate pariatur ab unde fugit veritatis, suscipit maiores esse officiis porro rem tenetur magnam laudantium veniam maxime nostrum?
          </p>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Veniam, quod dolore. Nemo voluptate pariatur ab unde fugit veritatis, suscipit maiores esse officiis porro rem tenetur magnam laudantium veniam maxime nostrum?
          </p>
          {/* Add more paragraphs as needed */}
        </div>
      </div>
    </div>
    </>
  );
}

export default PrivacyPolicy;
