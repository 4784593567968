import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Encrypt } from "../Components/Helper";


function Polls() {
  // You can use useState here if needed
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, [])
  const { category } = useParams();
  const [polls, setPolls] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const encoded = {
    'Sports': 's',
    'Politics': 'p',
    'Education': 'e',
    'News': 'n',
    'Animals': 'a',
    'Food': 'f'
  }
  useEffect(() => {
    async function getPolls() {
      try {
        const response = await axios.get('https://www.app.glocalpolls.com/api/category-polls/', {
          params: {
            token: localStorage.getItem('token'),
            category: encoded[category]
          }
        });
  
        setPolls(response.data['category_polls']);
      } catch (error) {
        toast.error('Error fetching polls');
      }
    }
  
    if (polls.length === 0) {
      getPolls();
    }
  }, []);

  useEffect(() => {
    const cleanup = async () => {
      document.querySelector('#scannerModal .btn-close')?.click();
    };

    window.addEventListener('beforeunload', cleanup);

    return () => {
      window.removeEventListener('beforeunload', cleanup);
      cleanup();
    };
  }, []);

  const filteredPolls = polls.filter(poll =>
    poll.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="container p-5">
      <div className="row">
        <h2 className="text-center mb-2">
          Polls in{" "}
          <span>
            <b>{category}</b>
          </span>
        </h2>
        <p className="mb-5" style={{textAlign:'center'}}>There are {polls.length} polls in {category}</p> 
        <div className="row mb-4 scan-row">
          <div className="col-lg-6 mb-3">
            <div className="qr-code-div">
              <button className="qr-code shadow-sm" data-bs-toggle="modal" data-bs-target="#scannerModal">
                scan for polling
              </button>
              <i className="fi fi-ss-qrcode"></i>
            </div>
          </div>

          <div className="col-lg-6 mb-3">
            <div className="search mb-4">
              <input onChange={(e) => setSearchQuery(e.target.value)} type="text" className="shadow-sm form-control" name="search-input" placeholder="Search a poll" />
              <i className="fi fi-br-search"></i>
            </div>
          </div>
        </div>
          {filteredPolls.map((poll, index) => (
            <div key={index} className="col-lg-3 col-md-6 mb-4">
              <div className="card simple_poll shadow-sm">
                <img
                  src={poll.image ? 'https://www.app.glocalpolls.com/' + poll.image : process.env.PUBLIC_URL + '/assets/images/dummy.webp'}
                  className="card-img-top"
                  alt="My Image"
                />

                <div className="card-body">
                  <span className="poll-active">active</span>

                  <h5 className="card-title">{poll.name}</h5>
                  <p className="card-text">
                  This poll was created by {poll.username}, {poll.votes} people have voted this.
                  </p>
                  <div className="d-grid gap-2">
                    <Link
                      className={`btn view-poll ${poll.user_has_voted ? 'btn-secondary' : 'submit_button'}`}
                      to={`/view-poll/${Encrypt(poll.id)}/`}
                      type="button"
                    >
                      {poll.user_has_voted ? 'Already Voted' : 'View Poll'}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}

      </div>
    </div>
  );
}

export default Polls;
