import React, { useEffect, useState, useMemo } from "react";
import { Badge, Divider, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import axios from "axios";

/** Notification released*/
const Notification = ({ list, setList }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [totalComments, setTotalComments] = useState(0);

  const open = Boolean(anchorEl);

  useEffect(() => {
    const pollEntries = Object.entries(list);
    const commentsCount = pollEntries.reduce((count, [, poll]) => count + poll.comments.length, 0);
    setTotalComments(commentsCount);
  }, [list]);

  const handleClick = (event) => {
    if(Object.keys(list).length !== 0)
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRead = async (commentId, pollId) => {
    await axios.post('https://www.app.glocalpolls.com/api/read-notification/', {
      token: localStorage.getItem('token'),
      id: commentId
    }).then((response) => {
      // Logic to delete the comment with the specified commentId
      const updatedList = { ...list };
      const poll = updatedList[pollId];
      poll.comments = poll.comments.filter(comment => comment.id !== commentId);

      // If the poll has no more comments, delete the poll
      if (poll.comments.length === 0) {
        delete updatedList[pollId];
      }
      // Update the state with the modified list
      setList(updatedList);
    })

    setAnchorEl(null);
  };

  const menuItems = useMemo(() => {
    return Object.entries(list).flatMap(([pollId, poll]) => [
      <p key={`${pollId}-notify`} className="poll-notify">{poll.name}</p>,
      <Divider key={`${pollId}-divider`} />,
      ...poll.comments.map((comment, index) => (
        <MenuItem key={`${pollId}-${index}`} onClick={() => handleRead(comment['id'], pollId)}>
          <Tooltip title={
            <Typography variant="caption" color="inherit" style={{ fontFamily: 'Montserrat' }}>
              Mark as read
            </Typography>
          } placement="bottom-end">
            <p className="notification">{comment['text']}</p>
          </Tooltip>
        </MenuItem>
      )),
    ]);
  }, [list]);

  return (
    <>
      <IconButton onClick={handleClick} style={{padding: 0}}>
        <Badge badgeContent={totalComments} color="primary">
          <i className="fi fi-rs-bell" style={{ fontSize: '28px', color: 'white' }}></i>
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {menuItems}
      </Menu>
    </>
  );
}

export default Notification;
