import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

function CategoryLink(props) {
  return (
    <div className="col-lg-2 col-6 mb-3">
      <Link to={`/polls/${props.category}/`} className="category_link">
        <i className={props.icon}></i>
        <h4>{props.category}</h4>
      </Link>
    </div>
  );
}

function CategoriesSection() {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, [])

  return (
    <div className="container">
      <section className="categories-section">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h3>Poll Categories</h3>
            <p>Browse poll based on categories</p>
          </div>
          <CategoryLink icon="fi fi-sr-volleyball" category="Sports" />
          <CategoryLink icon="fi fi-sr-people-poll" category="Politics" />
          <CategoryLink icon="fi fi-sr-graduation-cap" category="Education" />
          <CategoryLink icon="fi fi-sr-radio" category="News" />
          <CategoryLink icon="fi fi-sr-paw" category="Animals" />
          <CategoryLink icon="fi fi-sr-restaurant" category="Food" />
        </div>
      </section>
    </div>
  );
}

export default CategoriesSection;
