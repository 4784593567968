import React, { useEffect } from 'react';

function ContactUs() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, [])
  return (
    <>
    <div className="page-banner">
      <h1 className="page-title">Contact Us</h1>
      <div className="overlay"></div>
    </div>

    <div className="page-content">
      <div className="container">
        <div className="row about-us-row">
          <h3 className="text-center mb-5 mt-5">Let's Start a Conversation</h3>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h3>Something about us</h3>
            <p> Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet corporis illo, pariatur ipsa aut nostrum sint sunt recusandae! Dolores blanditiis quos fugiat debitis explicabo qui. Perspiciatis similique ad est culpa!
            </p>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laborum nostrum sed minus necessitatibus qui est facilis excepturi optio alias maxime, fugit minima illo totam deleniti perferendis corrupti quas quam! Eum.</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil doloribus quidem qui eum, enim accusantium, maxime optio rem quibusdam molestias repudiandae possimus, at asperiores impedit blanditiis odio nobis alias! Nostrum!</p>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div>
              <div className="contact-form-wrapper ">
                <form action="#" className="contact-form shadow-sm">
                  <h5 className="title mb-5">Contact us</h5>
                  <p className="description">Feel free to contact us if you need any assistance, any help or another question.
                  </p>
                  <div>
                    <input type="text" className="form-control rounded border-white mb-3 form-input" id="name" placeholder="Name" required />
                  </div>
                  <div>
                    <input type="email" className="form-control rounded border-white mb-3 form-input" placeholder="Email" required />
                  </div>
                  <div>
                    <textarea id="message" className="form-control rounded border-white mb-3 form-text-area" rows="5" cols="30" placeholder="Message" required></textarea>
                  </div>
                  <div className="submit-button-wrapper mt-5">
                    <input type="submit" value="Send" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default ContactUs;
