import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { ClipLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function RegisterModal() {
  const [isloading, setIsLoading] = useState(false);
  const [remember, setRemember] = useState(false);
  const [otp, setOTP] = useState(-1);
  const [valid, setValid] = useState(true)
  const navigate = useNavigate()
  let timeoutId;
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirm: '',
    area: 'eg-an'
  });
  const areas = {
    "Aswan": 'eg-an',
    "Asyut": 'eg-at',
    "Al Bahr al Ahmar": 'eg-ba',
    "Al Buhayrah": 'eg-bh',
    "Bani Suwayf": 'eg-bn',
    "Bur Sa`id": 'eg-bs',
    "Ad Daqahliyah": 'eg-dq',
    "Dumyat": 'eg-dt',
    "Al Fayyum": 'eg-fy',
    "Al Gharbiyah": 'eg-gh',
    "Al Iskandariyah": 'eg-ik',
    "Al Isma`iliyah": 'eg-is',
    "Janub Sina'": 'eg-js',
    "Al Jizah": 'eg-jz',
    "Kafr ash Shaykh": 'eg-ks',
    "Al Minufiyah": 'eg-mf',
    "Al Minya": 'eg-mn',
    "Matruh": 'eg-mt',
    "Al Qahirah": 'eg-qh',
    "Al Qalyubiyah": 'eg-ql',
    "Qina": 'eg-qn',
    "Suhaj": 'eg-sj',
    "Ash Sharqiyah": 'eg-sq',
    "Shamal Sina'": 'eg-ss',
    "As Suways": 'eg-sw',
    "Luxor": 'eg-uq',
    "Al Wadi at Jadid": 'eg-wj'
  };

  // Function to handle input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Function to handle form submission
  const handleLogin = async (event) => {
    event.preventDefault();

    let inputOTP = ''
    document.querySelectorAll('.digit-group input').forEach(function (input) {
      inputOTP += input.value
    })
    if (otp == inputOTP) {
      if (!valid) {
        toast.error('OTP has expired')
        return
      }
      clearTimeout(timeoutId)
      setIsLoading(true);
      try {
        const response = await axios.post('https://www.app.glocalpolls.com/api/register/', { ...formData, refresh: remember });
        localStorage.setItem('token', response.data['token']);
        document.querySelector('#registerModal .btn-close')?.click();
        navigate('/home')
      } catch (error) {
        toast.error('Error creating account!');
      }
      setIsLoading(false);
    }
    else
    toast.error('Invalid OTP')
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirm) {
      toast.error('Passwords do not match');
      return;
    }
    else if (!formData.password || !formData.name.trim() || !formData.email.trim()) {
      toast.error('Please enter all fields');
      return;
    }
    setIsLoading(true);

    await axios.get('https://www.app.glocalpolls.com/api/register/', { params: { email: formData.email, name: formData.name } })
      .then((response) => {
        setOTP(response.data['otp'])
        timeoutId = setTimeout(() => {
          setValid(false);
      }, 10 * 60 * 1000);
        setIsLoading(false);
      })
      .catch((e) => {
        toast.error('Email already exists');
        setIsLoading(false);
      })
  }

  useEffect(() => {
    document.querySelectorAll('.digit-group input').forEach(function (input) {

      input.addEventListener('paste', (e) => {
        e.preventDefault();

        // Get the pasted text
        const pastedText = (e.clipboardData || window.clipboardData).getData('text');

        // Check if the pasted text is a 6-digit OTP
        if (/^\d{6}$/.test(pastedText)) {
          // Distribute each digit to its corresponding input field
          document.querySelectorAll('.digit-group input').forEach((input, index) => {
            input.value = pastedText[index];
          });
        }
      });

      input.addEventListener('keyup', function (e) {
        var parent = this.parentElement;

        if (e.keyCode === 8 || e.keyCode === 37) {
          var prev = parent.querySelector('input#' + this.getAttribute('data-previous'));

          if (prev) {
            prev.select();
          }
        } else if ((e.keyCode >= 48 && e.keyCode <= 57) || e.keyCode === 39) {
          var next = parent.querySelector('input#' + this.getAttribute('data-next'));

          if (next) {
            next.select();
          } else {
            var autosubmit = parent.getAttribute('data-autosubmit');
            if (autosubmit) {
              document.getElementById('otp').click()
            }
          }
        }
      });
    });
  }, [otp]);

  return (
    <div className="modal fade" id="registerModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">Create an Account</h5>
            <button type="button" onClick={() => setOTP(-1)} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            {otp === -1 ? (
              <form className="login_form" onSubmit={handleVerify}>
                <div className="form-content">
                  <i className="fi fi-rr-circle-user"></i>
                  <p>Be an active member of your community</p>
                </div>
                <div className="mb-3">
                  <label htmlFor="nameinput" className="form-label">Name</label>
                  <input type="text" onChange={handleInputChange} name='name' placeholder="john" className="form-control" id="nameinput" />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email address</label>
                  <input type="email" onChange={handleInputChange} name='email' placeholder="john@gmail.com" className="form-control" id="email" aria-describedby="emailHelp" />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">Password</label>
                  <input type="password" onChange={handleInputChange} name='password' placeholder="password" className="form-control" id="password" />
                </div>
                <div className="mb-3">
                  <label htmlFor="confirm-password" className="form-label">Confirm Password</label>
                  <input type="password" placeholder="password" onChange={handleInputChange} name='confirm' className="form-control" id="confirm-password" />
                </div>
                <div className="mb-3">
                  <label htmlFor="area-select" className="form-label">Select Area</label>
                  <select className="form-control" id="area-select" onChange={handleInputChange} name='area'>
                    {Object.entries(areas).map(([area, key]) => (
                      <option key={key} value={key}>{area}</option>
                    ))}
                  </select>
                </div>
                <div className="mb-3 form-check">
                  <input type="checkbox" onChange={() => setRemember(!remember)} className="form-check-input" id="keep-loggedin" />
                  <label className="form-check-label" htmlFor="keep-loggedin">keep me loggedin</label>
                </div>
                <button type="submit" className="btn login_btn" disabled={isloading}>Verify Email
                  <ClipLoader className='ms-2' loading={isloading} color='white' size={20} />
                </button>
              </form>
            ) :
              (
                <form className="login_form" onSubmit={handleLogin}>
                  <label className="form-label">OTP</label>
                  <div className="mb-3 text-center digit-group" data-autosubmit={true}>
                    <input type="number" min={0} max={9} id="digit-1" name="digit-1" data-next="digit-2" />
                    <input type="number" min={0} max={9} id="digit-2" name="digit-2" data-next="digit-3" data-previous="digit-1" />
                    <input type="number" min={0} max={9} id="digit-3" name="digit-3" data-next="digit-4" data-previous="digit-2" />
                    <span class="splitter">&ndash;</span>
                    <input type="number" min={0} max={9} id="digit-4" name="digit-4" data-next="digit-5" data-previous="digit-3" />
                    <input type="number" min={0} max={9} id="digit-5" name="digit-5" data-next="digit-6" data-previous="digit-4" />
                    <input type="number" min={0} max={9} id="digit-6" name="digit-6" data-previous="digit-5" />
                  </div>
                  <button id='otp' type="submit" className="btn login_btn" disabled={isloading}>Register
                    <ClipLoader className='ms-2' loading={isloading} color='white' size={20} />
                  </button>
                </form>
              )}

            <p className="mt-4 mb-4 text-center">Already have an account? <a href="#" data-bs-dismiss="modal" aria-label="Close" data-bs-toggle="modal" data-bs-target="#loginModal">Login</a></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterModal;
