import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ClipLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function LoginModal() {
    const navigate = useNavigate()
    const [isloading, setIsLoading] = useState(false);
    const [otp, setOTP] = useState(-1);
    const [email, setEmail] = useState('')
    const [change, setChange] = useState(false)
    const [password, setPassword] = useState('')
    const [confirm, setConfirm] = useState('')
    const [valid, setValid] = useState(true)
    let timeoutId;

    const handleEmailSubmit = async(e) => {
        e.preventDefault();
        setIsLoading(true)
        await axios.get('https://www.app.glocalpolls.com/api/update-password/', {params: {email: email}})
        .then((response) => {
            setOTP(response.data['otp'])
            timeoutId = setTimeout(() => {
                setValid(false);
            }, 10 * 60 * 1000);
            setIsLoading(false)
        })
        .catch((err) => {
            toast.error('Email not found!')
            setIsLoading(false)
        })
    }

    const handleOTPSubmit = (e) => {
        e.preventDefault();
        let inputOTP = ''
        document.querySelectorAll('.digit-group input').forEach(function(input) {
            inputOTP += input.value
        })
        if (otp == inputOTP){
            if (valid){
                setChange(true)
                clearTimeout(timeoutId)
            }else
                toast.error('OTP has expired!')    
        }
        else
            toast.error('Invalid OTP')
    }

    const handlePasswordSubmit = async(e) => {
        e.preventDefault()
        if (password !== confirm)
            toast.error('Passwords do not match!')
        else {
            await axios.post('https://www.app.glocalpolls.com/api/update-password/', {email: email, password: password})
            .then((response) => {
                document.querySelector('#forgotModal .btn-close')?.click();
                toast.success('Updated Password Successfully')
                setIsLoading(false)
            })
            .catch((err) => {
                toast.error('Error occured while updating password!')
                setIsLoading(false)
            })
        }
    }

    const handleClose = () => {
        setOTP(-1)
        setChange(false)
        setConfirm('')
    }

    useEffect(() => {
        document.querySelectorAll('.digit-group input').forEach(function(input) {

            input.addEventListener('paste', (e) => {
                e.preventDefault();
    
                // Get the pasted text
                const pastedText = (e.clipboardData || window.clipboardData).getData('text');
            
                // Check if the pasted text is a 6-digit OTP
                if (/^\d{6}$/.test(pastedText)) {
                    // Distribute each digit to its corresponding input field
                    document.querySelectorAll('.digit-group input').forEach((input, index) => {
                        input.value = pastedText[index];
                    });
                }
            });
          
            input.addEventListener('keyup', function(e) {
              var parent = this.parentElement;
          
              if (e.keyCode === 8 || e.keyCode === 37) {
                var prev = parent.querySelector('input#' + this.getAttribute('data-previous'));
          
                if (prev) {
                  prev.select();
                }
              } else if ((e.keyCode >= 48 && e.keyCode <= 57) || e.keyCode === 39) {
                var next = parent.querySelector('input#' + this.getAttribute('data-next'));
          
                if (next) {
                  next.select();
                } else {
                  var autosubmit = parent.getAttribute('data-autosubmit');
                  if (autosubmit) {
                    document.getElementById('otp').click()
                  }
                }
              }
            });
          });
      }, [otp]);

    return (
        <div className="modal fade" id="forgotModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">Forgot Password</h5>
                        <button onClick={handleClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">

                        {otp === -1 ?
                            <form className="login_form" onSubmit={handleEmailSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="phone_num" className="form-label">Email</label>
                                    <input onChange={(e) => setEmail(e.target.value)} type="email" name='phone' placeholder="john@gmail.com" className="form-control" id="phone_num" />
                                </div>
                                <button type="submit" className="btn login_btn" disabled={isloading}>Send OTP
                                    <ClipLoader className='ms-2' loading={isloading} color='white' size={20} />
                                </button>
                            </form>
                            :
                            <>
                                {change ?
                                    <form className="login_form" onSubmit={handlePasswordSubmit}>
                                        <div className="mb-3">
                                            <label htmlFor="password" className="form-label">New Password</label>
                                            <input onChange={(e) => setPassword(e.target.value)} type="password" name='password' placeholder="password" className="form-control" id="password" />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="confirm-password" className="form-label">Confirm Password</label>
                                            <input value={confirm} onChange={(e) => setConfirm(e.target.value)} type="password" placeholder="password" name='confirm' className="form-control" id="confirm-password" />
                                        </div>
                                        <button type="submit" className="btn login_btn" disabled={isloading}>Change Password
                                            <ClipLoader className='ms-2' loading={isloading} color='white' size={20} />
                                        </button>
                                    </form>
                                    :
                                    <form className="login_form" onSubmit={handleOTPSubmit}>
                                            <label className="form-label">OTP</label>
                                        <div className="mb-3 text-center digit-group" data-autosubmit={true}>
                                            <input type="number" min={0} max={9} id="digit-1" name="digit-1" data-next="digit-2" />
                                            <input type="number"  min={0} max={9} id="digit-2" name="digit-2" data-next="digit-3" data-previous="digit-1" />
                                            <input type="number" min={0} max={9} id="digit-3" name="digit-3" data-next="digit-4" data-previous="digit-2" />
                                            <span class="splitter">&ndash;</span>
                                            <input type="number" min={0} max={9} id="digit-4" name="digit-4" data-next="digit-5" data-previous="digit-3" />
                                            <input type="number" min={0} max={9} id="digit-5" name="digit-5" data-next="digit-6" data-previous="digit-4" />
                                            <input type="number" min={0} max={9} id="digit-6" name="digit-6" data-previous="digit-5" />
                                        </div>
                                        <p className="text-center"><a onClick={() => setOTP(-1)} href="#" >Re-enter Email</a></p>
                                        <button id='otp' type="submit" className="btn login_btn" disabled={isloading}>Verify OTP
                                            <ClipLoader className='ms-2' loading={isloading} color='white' size={20} />
                                        </button>
                                    </form>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginModal;
