import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';

const Pricing = () => {
  const navigate = useNavigate();
  const [usertype, setUserType] = useState()
  const [isloading, setIsLoading] = useState(0);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, [])

  useEffect(() => {
    async function fetchUser() {
      try {
        const response = await axios.post('https://www.app.glocalpolls.com/api/get-user/', {token: localStorage.getItem('token')});
        setUserType(response.data['type'])
      } catch (error) { 
        toast.error('Error validating user');
      }
    } 

    fetchUser();
  }, []);

  const handleGetStartedClick = async (newtype) => {
    if (newtype === usertype || newtype === 'R') {
      if (newtype === 'R' && usertype !== 'R') {
        await axios.post('https://www.app.glocalpolls.com/api/update-type/', {
        token: localStorage.getItem('token'),
        type: 'R'
      }).then((response) => {
        toast.success('Successfully updated user type')
      }).catch((error) => {
        toast.error('Error occurred while updating')
      })
      }
      navigate('/create-poll')
    }
    else {
      setIsLoading(newtype === 'T' ? 2: 3)
      axios.post('https://www.app.glocalpolls.com/api/payment/', {
        token: localStorage.getItem('token'),
        amount: newtype === 'T' ? 2499 : 3999
      }).then((response) => {
        setIsLoading(0)
        const token = response.data['payment_token']
        window.location.href = 'https://pakistan.paymob.com/api/acceptance/iframes/150129?payment_token=' + token;
      }).catch((error) => {
        toast.error('Error! Please try again')
      })
    }
  };

  return (
    <div className="container">
      <section className="pricing-section">
        <div className="row">
          <main>
            <h1 className="user-type-heading">User Type</h1>
            <p className="user-type-tagline">
              Select a User Type following the given specification
            </p>

            <section className="pricing-container">
              {/* Container 1 */}
              <article className="pricing container-1">
                <h2>Registered User</h2>
                <p className="price-1">
                  <span className="dollar-sign">$</span>0
                </p>
                <ul>
                <li>YOU WILL GET</li>
                  <hr />
                  <li>Poll by Registered Users</li>
                  <hr />
                  <li>Limited poll results</li>
                  <hr />
                  <li>Poll Voting upto 2k People</li>
                  <hr />
                </ul>
                <button className="get-started" onClick={() => handleGetStartedClick('R')}>
                  {usertype === 'R'? 'Continue': 'Get Started'}
                </button>
              </article>
              {/* Container 2 */}
              <article className="pricing container-2">
                <h2>Trusted User</h2>
                <p className="price-2">
                  <span className="dollar-sign">$</span>24.99
                </p>
                <ul>
                <li>YOU WILL GET</li>
                  <hr />
                  <li>Polls by Registered and Trusted Users</li>
                  <hr />
                  <li>Graph and Pie Charts in Results</li>
                  <hr />
                  <li>Poll Voting upto 6k People</li>
                  <hr />
                </ul>
                <button className="get-started" disabled={isloading === 2} onClick={() => handleGetStartedClick('T')}>
                {usertype === 'T'? 'Continue': 'Get Started'}
                {isloading === 2 && <ClipLoader className='ms-2' color='white' size={20}/>}
                </button>
              </article>
              {/* Container 3 */}
              <article className="pricing container-3">
                <h2>Authentic User</h2>
                <p className="price-3">
                  <span className="dollar-sign">$</span>39.99
                </p>
                <ul>
                  
                  <li>YOU WILL GET</li>
                  <hr />
                  <li>Polls by Registered, Trusted and Authentic Users</li>
                  <hr />
                  <li>All possible results + Graphical location of Voters</li>
                  <hr />
                  <li>Poll Voting upto 10k+ People</li>
                  <hr />
                </ul>
                <button className="get-started" disabled={isloading === 3} onClick={() => handleGetStartedClick('A')}>
                {usertype === 'A'? 'Continue': 'Get Started'}
                {isloading === 3 && <ClipLoader className='ms-2' color='#14AAAB' size={20}/>}
                </button>
              </article>
            </section>
          </main>
        </div>
      </section>
    </div>
  );
};

export default Pricing;
