import React, { useState, useRef, useEffect } from 'react';
import QrReader from "react-qr-reader";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function Scanner() {
    const [show, setShow] = useState(false)
    const [test, setTest] = useState(false)

    const qrRef = useRef(null);
    const navigate = useNavigate()

    const handleScan = async (scanData) => {
        if (scanData && scanData !== "") {
            try {
            const scannedUrl = new URL(scanData);
            const currentUrl = new URL(window.location.href);
            if (scannedUrl.origin === currentUrl.origin) {
                document.querySelector('#scannerModal .btn-close')?.click();
                navigate(scannedUrl.pathname)
              } else {
                toast.error('Invalid QR code! Try again');
              }
            }
            catch {
                toast.error('Invalid QR code! Try again');
            }
            if (test)
                setTest(false)
        }
    };

    const handleError = (err) => {
        toast.error('Invalid QR code! Try again');
    };

    const openDialog = () => {
        setTest(true)
        setTimeout(() => qrRef.current?.openImageDialog(), 100)
    };

    useEffect(() => {
        const startScanner = () => {
            setShow(true)
        };

        const stopScanner = () => {
            setShow(false)
        };

        const modalElement = document.getElementById('scannerModal');
        modalElement.addEventListener('show.bs.modal', startScanner);
        modalElement.addEventListener('hide.bs.modal', stopScanner);

        return () => {
            modalElement.removeEventListener('show.bs.modal', startScanner);
            modalElement.removeEventListener('hide.bs.modal', stopScanner);
        };
    }, []);

    return (
        <div className="modal fade" id="scannerModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">Scan for Instant Polling</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>          </div>
                    <div className="modal-body">
                        <div className="scan-qr-code">
                            {/* QR Code Scanner using camera */}
                            {show &&
                                <QrReader
                                    facingMode="environment"
                                    delay={1000}
                                    onError={handleError}
                                    onScan={handleScan}
                                    style={{ width: "300px" }}
                                />
                            }
                            {test && 
                                <QrReader legacyMode ref={qrRef} onScan={handleScan} onError={handleError} style={{width: 0}}/>
                            }
                            <button className="mt-2 btn w-100 submit_button" onClick={openDialog}> 
                                Upload QR Code
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Scanner;
