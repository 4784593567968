import React, { useState } from 'react';
import axios from 'axios';
import { ClipLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function LoginModal() {
  const navigate = useNavigate()
  const [isloading, setIsLoading] = useState(false);
  const [remember, setRemember] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  // Function to handle input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Function to handle form submission
  const handleLogin = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post('https://www.app.glocalpolls.com/api/login/', { ...formData, refresh: remember });
      localStorage.setItem('token', response.data['token']);
      document.querySelector('#loginModal .btn-close')?.click();
      navigate('/home')
    } catch (error) {
      toast.error('Invalid email or password')
    }
    setIsLoading(false);
  };

  return (
    <div className="modal fade" id="loginModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">Login</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <form className="login_form" onSubmit={handleLogin}>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                <input type="email" name='email' onChange={handleInputChange} placeholder="john@gmail.com" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                <input type="password" name='password' onChange={handleInputChange} placeholder="password" className="form-control" id="exampleInputPassword1" />
              </div>
              <div className="mb-3 form-check">
                <input type="checkbox" onChange={() => setRemember(!remember)} className="form-check-input" id="exampleCheck1" />
                <label className="form-check-label" htmlFor="exampleCheck1">keep me loggedin</label>
              </div>
              <button type="submit" className="btn login_btn" disabled={isloading}>Login
                <ClipLoader className='ms-2' loading={isloading} color='white' size={20} />
              </button>
            </form>
            <p className="mt-4 mb-1 text-center">Don't have an account? <a href="#" data-bs-dismiss="modal" aria-label="Close" data-bs-toggle="modal" data-bs-target="#registerModal">Create One</a></p>
            <p className="text-center">Forgot Password? <a href="#" data-bs-dismiss="modal" aria-label="Close" data-bs-toggle="modal" data-bs-target="#forgotModal">Click here</a></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginModal;
