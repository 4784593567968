import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Chart from 'chart.js/auto';
import Highcharts from 'highcharts/highmaps';
import { toast } from 'react-toastify';
import { Decrypt, Encrypt } from '../Components/Helper';


const PollStats = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, [])

  const navigate = useNavigate()
  const {id} = Decrypt();
  const colors = ["#4fda4f", "#fb5151", "grey"]
  const [pollData, setPollData] = useState({})
  const [name, setName] = useState('');
  const [votes, setVotes] = useState(0)
  const [stats, setStats] = useState([]);
  const [dates, setDates] = useState([]);
  const [usertype, setUserType] = useState('')
  const [voteareas, setVoteAreas] = useState({})
  const [period, setPeriod] = useState(7);
  const duration = {
    'R': 7,
    'T': 15,
    'A': 30
  }

  const answers = { 1: ['Yes', 'No', 'Maybe'], 3: ['Likely', 'Neutral', 'Not Likely'] }
  const areas = {
    'eg-an': "Aswan", 'eg-at': "Asyut", 'eg-ba': "Al Bahr al Ahmar", 'eg-bh': "Al Buhayrah"
    , 'eg-bn': "Bani Suwayf", 'eg-bs': "Bur Sa`id", 'eg-dq': "Ad Daqahliyah", 'eg-dt': "Dumyat"
    , 'eg-fy': "Al Fayyum", 'eg-gh': "Al Gharbiyah", 'eg-ik': "Al Iskandariyah", 'eg-is': "Al Isma`iliyah"
    , 'eg-js': "Janub Sina'", 'eg-jz': "Al Jizah", 'eg-ks': "Kafr ash Shaykh", 'eg-mf': "Al Minufiyah"
    , 'eg-mn': "Al Minya", 'eg-mt': "Matruh", 'eg-qh': "Al Qahirah", 'eg-ql': "Al Qalyubiyah"
    , 'eg-qn': "Qina", 'eg-sj': "Suhaj", 'eg-sq': "Ash Sharqiyah", 'eg-ss': "Shamal Sina'"
    , 'eg-sw': "As Suways", 'eg-uq': "Luxor", 'eg-wj': "Al Wadi at Jadid"
  }

  function updateCountdown(endTime) {
    // Get the element with class "time_remaining"
    var countdownElement = document.querySelector('.time_remaining');
    var totalRemainingSeconds = Math.floor((endTime - Date.now()) / 1000);

    // Update the timer every 1 second
    var countdownInterval = setInterval(function () {
        if (totalRemainingSeconds <= 0) {
            // If the countdown is complete, display a message or take any other action
            countdownElement.textContent = 'Poll Expired!';
            clearInterval(countdownInterval); // Stop the timer 
        } else {
            // Calculate days, hours, minutes, and seconds
            var remainingDays = Math.floor(totalRemainingSeconds / (3600 * 24));
            var remainingHours = Math.floor((totalRemainingSeconds % (3600 * 24)) / 3600);
            var remainingMinutes = Math.floor((totalRemainingSeconds % 3600) / 60);
            var remainingSeconds = totalRemainingSeconds % 60;

            // Format the time to include leading zeros
            var formattedTime =
                (remainingDays > 0 ? remainingDays + 'd ' : '') +
                (remainingHours < 10 ? '0' : '') + remainingHours + 'h:' +
                (remainingMinutes < 10 ? '0' : '') + remainingMinutes + 'm:' +
                (remainingSeconds < 10 ? '0' : '') + remainingSeconds + 's';

            countdownElement.textContent = formattedTime;

            // Subtract 1 second from the remaining time
            totalRemainingSeconds -= 1;
        }
    }, 1000); // Update every 1 second (1000 milliseconds)
}



  useEffect(() => {
    async function getPoll() {
      await axios.get('https://www.app.glocalpolls.com/api/get-poll/', {
        params: {
          token: localStorage.getItem('token'),
          poll_id: id
        }
      }).then((response) => {
        setPollData(response.data['pollData'])
        setStats(response.data['question_percentages'])
        setName(response.data['name'])
        setVotes(response.data['votes'])
        setDates(response.data['dates'])
        setUserType(response.data['type'])
        setVoteAreas(response.data['areas'])
      }).catch((error) => {
        toast.error('You do not have access to this poll!')
        navigate('/home')
      })
    }

    getPoll();
  }, [])

  useEffect(() => {
    if (name) {
      var pollEndTime = new Date(pollData.created)
      pollEndTime.setDate(pollEndTime.getDate() + pollData.duration);
      updateCountdown(pollEndTime.getTime());
    }
  }, [pollData])



  useEffect(() => {
    Object.entries(stats).forEach(([question, answerPercentages], index) => {
      const chartId = `pieChart${index}`;
      const chartCanvas = document.getElementById(chartId);
      Chart.getChart(chartCanvas)?.destroy();

      if (chartCanvas) {
        const ctx = chartCanvas.getContext('2d');
        new Chart(ctx, {
          type: 'pie',
          data: {
            labels: pollData.scale % 2 == 0 ? Object.keys(answerPercentages) : answers[pollData.scale],
            datasets: [
              {
                data: Object.values(answerPercentages),
                backgroundColor: pollData.scale % 2 === 1 ? ["#4fda4f","#fb5151", "grey"] : [
                  '#FF6384', // Red
                  '#36A2EB', // Blue
                  '#FFCE56', // Yellow
                  '#4BC0C0', // Turquoise
                  '#9966CC', // Lavender
                  '#32CD32', // Lime Green
                  '#FF4500', // Orange Red
                  '#1E90FF', // Dodger Blue
                  '#FF69B4', // Hot Pink
                  '#00FF7F', // Spring Green
                ],
              },
            ],
          },
          options: {
            title: {
              display: true,
              text: question,
            },

          },
        });
      }
    });
  }, [stats]);

  useEffect(() => {
    if (usertype !== 'R') {
      let timePeriod;
      if (period === '12') {
        timePeriod = Array.from({ length: 12 }, (_, i) => {
          const date = new Date();
          date.setMonth(date.getMonth() - 11 + i);
          return date.toISOString().slice(0, 7); // Keep only the year and month
        });
      }
      else {
        timePeriod = Array.from({ length: period }, (_, i) => {
          const date = new Date();
          date.setDate(date.getDate() - period + i + 1);
          return date.toISOString().split('T')[0];
        });
      }
      const initialDictionary = timePeriod.reduce((dict, date) => {
        dict[date] = 0;
        return dict;
      }, {});

      // Count occurrences of each date in the dates array
      const dateCounts = dates.reduce((counts, date) => {
        const formattedDate = new Date(date).toISOString().split('T')[0];
        const key = period === '12' ? formattedDate.slice(0, 7) : formattedDate; // Keep only the year and month for 12 months
        if (counts.hasOwnProperty(key)) {
          counts[key] += 1;
        }
        return counts;
      }, initialDictionary);

      const viewsChartCanvas = document.getElementById('viewsChart');
      Chart.getChart(viewsChartCanvas)?.destroy();

      // Create a new chart with proper configuration
      const ctx = viewsChartCanvas.getContext('2d');
      new Chart(ctx, {
        type: 'line',
        data: {
          labels: timePeriod,
          datasets: [
            {
              label: 'Number of Votes',
              data: dateCounts,
              fill: false,
              borderColor: 'rgba(75,192,192,1)', // You can customize the color
            },
          ],
        },
        options: {
          scales: {
            x: {
              type: 'category',
              labels: timePeriod,
            },
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    }
  }, [dates, period]);

  useEffect(() => {
    async function heatmap() {
      const topology = await fetch(
        'https://code.highcharts.com/mapdata/countries/eg/eg-all.topo.json'
      ).then(response => response.json());

      // Prepare demo data. The data is joined to map using value of 'hc-key'
      // property by default. See API docs for 'joinBy' for more info on linking
      // data and map.

      let data = [
        ['eg-5847', 0], ['eg-ba', 0], ['eg-js', 0], ['eg-uq', 0],
        ['eg-is', 0], ['eg-gh', 0], ['eg-mf', 0], ['eg-qh', 0],
        ['eg-ql', 0], ['eg-sq', 0], ['eg-ss', 0], ['eg-sw', 0],
        ['eg-dq', 0], ['eg-bs', 0], ['eg-dt', 0], ['eg-bh', 0],
        ['eg-mt', 0], ['eg-ik', 0], ['eg-jz', 0], ['eg-fy', 0],
        ['eg-wj', 0], ['eg-mn', 0], ['eg-bn', 0], ['eg-ks', 0],
        ['eg-at', 0], ['eg-an', 0], ['eg-qn', 0], ['eg-sj', 0]
      ];

      for (let i = 0; i < data.length; i++) {
        if (voteareas.hasOwnProperty(data[i][0]))
          data[i][1] = voteareas[data[i][0]]
      }

      // Create the chart
      Highcharts.mapChart('container', {
        chart: {
          map: topology
        },

        title: {
          text: 'Voter Distribution',
          style: {
            fontFamily: 'Montserrat'
          }
        },

        subtitle: {
          // text: 'Source map: <a href="http://code.highcharts.com/mapdata/countries/eg/eg-all.topo.json">Egypt</a>'
        },

        mapNavigation: {
          enabled: true,
          buttonOptions: {
            verticalAlign: 'bottom'
          }
        },

        colorAxis: {
          min: 0,
          stops: [
            [0, '#fff'],
            [1, '#14aaab']
          ]
        },

        series: [{
          data: data,
          name: 'Number of votes',
          dataLabels: {
            enabled: true,
            format: '{point.name}',
            style: {
              fontFamily: 'Montserrat',
              fontWeight: 400,
            }
          }
        }]
      });
    }

    if (usertype === 'A')
      heatmap()
  }, [voteareas])

  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);
    return date.toLocaleDateString("en-GB").replace(/\//g, '-');
  };

  const expiryDate = (created) => {
    const newDate = new Date(created);
    newDate.setDate(newDate.getDate() + pollData.duration);
    return newDate.toLocaleDateString("en-GB").replace(/\//g, '-');;
  }

  function copyToClipboard() {
    const url = new URL(window.location.href)
    navigator.clipboard.writeText(url.origin + '/view-poll/' + Encrypt(pollData.id, true)).catch(() => { });
    toast.success('Copied to clipboard')
  }

  return (
    <div className="container pt-5 pb-5">
      <section className="poll-result-section">
        <div className="row">
          <div className="col-lg-6 pol-res-col shadow-sm">
            <div className="bnt-div">
              <h3 className="poll-title">{pollData.name}</h3>
            </div>
            <p className="poll-description">This poll was created by {name}, {votes} people have voted this.</p>
            <button className="copy_poll" onClick={copyToClipboard}>
              <i className="fi fi-sr-copy-alt me-2"></i> Get poll link
            </button>
            <button className="copy_poll me-2" data-bs-toggle="modal" data-bs-target="#qrCodeModal">
              <i className="fi fi-sr-copy-alt me-2"></i> Get QR Code
            </button>
            <div className="dates">
              <p className="created_at">Start: <span>{formatDate(pollData.created)}</span> </p>
              <p className="created_expire">End: <span>{expiryDate(pollData.created)}</span> </p>
              <p className="time_remaining"></p>
            </div>
            {Object.entries(stats).map(([question, answerPercentages], index) => (
              <div className='reslts' key={index}>
                <p>{question}</p>
                {Object.entries(answerPercentages).map(([answer, percentage], ans_index) => (
                  <>
                    {(percentage !== 0 || pollData.scale % 2 === 1) &&
                      <div className='progress' key={ans_index}>
                        <div
                          key={ans_index}
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: `${percentage}%`, backgroundColor: pollData.scale % 2 === 1 ? colors[ans_index] : '#0d6efd' }}
                          aria-valuenow={percentage}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                        </div>
                        <span className='vote-name'>{pollData.scale % 2 === 1 ? answers[pollData.scale][ans_index] : answer}</span>
                        <span className='vote-num'>{`${parseInt(percentage)}%`}</span>
                      </div>
                    }
                  </>
                ))}
              </div>
            ))}
            <br /><br />
            <div className='row'>
              {Object.entries(stats).map(([question, answerPercentages], index) => (
                <div key={index} className='col-sm-6'>
                  <h4>{question}</h4>
                  <canvas id={`pieChart${index}`} width="100px" height="100px"></canvas>
                </div>
              ))}
            </div>

            {usertype !== 'R' && (
              <>
                <br /><br />
                <div className="select-duration">
                  <h4>Voters</h4>
                  <select className="form-select" aria-label="Default select example" onChange={(e) => setPeriod(e.target.value)}>
                    <option value={7}>Last 7 days</option>
                    <option value={30}>Last 30 days</option>
                    <option value={12}>Last 12 months</option>
                  </select>
                </div>
                <canvas id="viewsChart"></canvas>
              </>
            )}

            {usertype === 'A' && (
              <>
                <br /><br /><br />
                <div id="container"></div>
                <br /><br /><br />

                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Area</th>
                      <th>Votes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(voteareas).map(([area, votes], index) => (
                      <tr key={index}>
                        <td>{areas[area]}</td>
                        <td>{votes}</td>
                      </tr>
                    ))}

                  </tbody>
                </table>
              </>
            )}
          </div>
          <div className="col-lg-6 img-col">
            <img
              className="poll_image"
              src={pollData.image ? 'https://www.app.glocalpolls.com/' + pollData.image : process.env.PUBLIC_URL + '/assets/images/poll.jpg'}
              alt=""
            />          </div>
        </div>
      </section>
    </div>
  );
}

export default PollStats;
