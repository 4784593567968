import React, { useRef } from 'react';
import QRCode from 'qrcode.react';

function downloadQRCode() {
  const qrCodeImage = document.getElementById('qrCodeImage');

  if (qrCodeImage) {
    const margin = 20; // Adjust the margin as needed
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const width = qrCodeImage.width + 2 * margin;
    const height = qrCodeImage.height + 2 * margin;

    canvas.width = width;
    canvas.height = height;
    
    // Add white padding
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, width, height);

    ctx.drawImage(qrCodeImage, margin, margin);

    const dataUrl = canvas.toDataURL('image/png');
    const a = document.createElement('a');
    a.href = dataUrl;
    a.download = 'qrcode.png';
    a.click();
  }
}


function QRCodeModal() {
  const pollUrl = ()  => {
    const url = window.location.href;
    return url.replace('poll-stats', 'view-poll');
  }
  

  return (
    <div className="modal fade" id="qrCodeModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">Scan for Instant Polling</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="scan-qr-code">
              {/* Use the id for the QR code image to reference it */}
              <QRCode id="qrCodeImage" value={pollUrl()} size={300} />
              <button className="btn submit_button w-100 mt-3" onClick={downloadQRCode}>
                Download QR Code
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QRCodeModal;
