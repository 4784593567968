import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import IndexPage from "./Pages/Index";
import AboutPage from "./Pages/About";
import ContactPage from "./Pages/Contact-us";
import TermsPage from "./Pages/Terms";
import PrivacyPage from "./Pages/Privacy";
import CreatedPollPage from "./Pages/Created-poll";
import HomePage from "./Pages/Home";
import CreatePollPage from "./Pages/Create-poll";
import ViewPollPage from "./Pages/ViewPoll";
import PollStatsPage from "./Pages/PollStats";
import PricingPage from "./Pages/Pricing";
import UserForm from "./Pages/User-form";
import WelcomePage from "./Pages/Welcome";
import Polls from "./Pages/Polls";
import PollPage from "./Pages/poll-page";
import "./App.css";
import Participated from "./Pages/Participated";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  
  return (
    <BrowserRouter>
      <div className="App"> 
        <Routes>
          {/* route without navbar and footer */}
          <Route path="/" element={<WelcomePage />}></Route>
          {/* rest of the routes */}
          <Route
            path="/home"
            element={
              <>
                <Navbar></Navbar>
                <IndexPage></IndexPage>
                <Footer></Footer>
              </>
            } 
          />
          <Route
            path="/about-us"
            element={
              <>
                <Navbar></Navbar>
                <AboutPage />
                <Footer></Footer>
              </>
            }
          />
          <Route
            path="/contact-us"
            element={
              <>
                <Navbar></Navbar>
                <ContactPage />
                <Footer></Footer>
              </>
            }
          />
          <Route
            path="/terms-and-conditions"
            element={
              <>
                <Navbar></Navbar>
                <TermsPage />
                <Footer></Footer>
              </>
            }
          />
          <Route
            path="/privacy"
            element={
              <>
                <Navbar></Navbar>
                <PrivacyPage />
                <Footer></Footer>
              </>
            }
          />
          <Route
            path="/created"
            element={
              <>
                <Navbar></Navbar>
                <CreatedPollPage />
                <Footer></Footer>
              </>
            }
          />
          
          <Route
            path="/polls"
            element={
              <>
                <Navbar></Navbar>
                <HomePage />
                <Footer></Footer>
              </>
            }
          />
          <Route
            path="/create-poll"
            element={
              <>
                <Navbar></Navbar>
                <CreatePollPage />
                <Footer></Footer>
              </>
            }
          />
          <Route
            path="/view-poll/:id"
            element={
              <>
                <Navbar></Navbar>
                <ViewPollPage />
                <Footer></Footer>
              </>
            }
          />
          <Route
            path="/poll-stats/:id"
            element={
              <>
                <Navbar></Navbar>
                <PollStatsPage />
                <Footer></Footer>
              </>
            }
          />
          <Route
            path="/pricing"
            element={
              <>
                <Navbar></Navbar>
                <PricingPage />
                <Footer></Footer>
              </>
            }
          />
          <Route
            path="/UserForm"
            element={
              <>
                <Navbar></Navbar>
                <UserForm />
                <Footer></Footer>
              </>
            }
          />
          <Route
            path="/polls/:category"
            element={
              <>
                <Navbar></Navbar>
                <Polls />
                <Footer></Footer>
              </>
            }
          />
          <Route
            path="/participated"
            element={
              <>
                <Navbar></Navbar>
                <Participated />
                <Footer></Footer>
              </>
            }
          />
         
        </Routes>
      </div>
      <ToastContainer/>
    </BrowserRouter>
  );
}

export default App;
