import React, { useEffect, useState } from "react";
import ScanSearchComponent from "../Components/Scan-search";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Encrypt } from "../Components/Helper";

function PollStatsCard(props) {
  return (
    <div className="col-lg-3 mb-4">
      <div className="card shadow-sm">
        <div className="row">
          <div className="col-lg-9 card-content">
            <h3>{props.total}</h3>
            <p>{props.label}</p>
            <p className="increase-p">
              <i className="fi fi-sr-arrow-trend-up"></i> {props.increase} from
              last month
            </p>
          </div>
          <div className="col-lg-3">
            <i className={props.icon}></i>
          </div>
        </div>
      </div>
    </div>
  );
}

function PollCard(props) {
  const poll = props.poll;

  return (
    <div className="col-lg-4">
      <div className="card created-poll-card shadow-sm">
        <img
          src={poll.image ? 'https://www.app.glocalpolls.com/' + poll.image : process.env.PUBLIC_URL + '/assets/images/dummy.webp'}
          className="card-img-top"
          alt='Poll Image'
        />
        <div className="card-body">
        <span className="poll-active" style={{color: poll.expired ? 'red': '#2C6877'}}>{poll.expired ? 'expired' : 'active'}</span>
          <h5 className="card-title">
            {poll.name} <span className="active_poll"></span>
          </h5>
          <div className="poll-results">
            <table className="table table-bordered text-center">
              <thead>
                <tr>
                  <th>
                    <i className="fi fi-sr-eye"></i> <br /> Impressions
                  </th>
                  <th>
                    <i className="fi fi-sr-interactive"></i> <br /> Clicks
                  </th>
                  <th>
                    <i className="fi fi-sr-vote-yea"></i> <br /> Votes
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{poll.impressions}</td>
                  <td>{poll.clicks}</td>
                  <td>{poll.votes}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <Link to={`/poll-stats/${Encrypt(poll.id)}/`} className="btn view-results">
            View More
          </Link>
        </div>
      </div>
    </div>
  );
}

function PollPage() {
  const [polls, setPolls] = useState([]); // State to store the retrieved polls
  const [active, setActive] = useState(0);
  const [createdToday, setCreatedToday] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, [])

  useEffect(() => {
    async function fetchPolls() {
      try {
        const response = await axios.get('https://www.app.glocalpolls.com/api/create-poll/', {
          params: { token: localStorage.getItem('token') }
        });

        const retrievedPolls = response.data['user_polls'];
        setPolls(retrievedPolls);

        const activePolls = retrievedPolls.filter(poll => !poll.expired);
        setActive(activePolls.length);

        const today = new Date();
        const createdTodayPolls = retrievedPolls.filter(poll => {
          const pollDate = new Date(poll.created);
          return pollDate.getDate() === today.getDate() &&
            pollDate.getMonth() === today.getMonth() &&
            pollDate.getFullYear() === today.getFullYear();
        });
        setCreatedToday(createdTodayPolls.length);

      } catch (error) {
        toast.error('User not authenticated');
        navigate('/home')
      }
    }

    // Call the fetchPolls function when the component is mounted
    fetchPolls();
  }, []);

  const filteredPolls = polls.filter(poll =>
    poll.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="container pt-5 pb-5">

      <div className="row mb-3 mt-3">
        <div className="col-lg-12 text-center">
          <h4 className="text-center">Created Polls</h4>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia
            quo veritatis, exercitationem
          </p>
        </div>
      </div>

      <div className="row mb-4 scan-row">
        <div className="col-lg-6 mb-3">
          <div className="qr-code-div">
            <button className="qr-code shadow-sm" data-bs-toggle="modal" data-bs-target="#scannerModal">
              scan for polling
            </button>
            <i className="fi fi-ss-qrcode"></i>
          </div>
        </div>

        <div className="col-lg-6 mb-3">
          <div className="search mb-4">
            <input onChange={(e) => setSearchQuery(e.target.value)} type="text" className="shadow-sm form-control" name="search-input" placeholder="Search a poll" />
            <i className="fi fi-br-search"></i>
          </div>
        </div>
      </div>

      <div className="row mb-4 mt-4 poll-stats">
        <PollStatsCard
          total={polls.length}
          label="Total polls"
          increase="10%"
          icon="fi fi-sr-people-poll"
        />
        <PollStatsCard
          total={active}
          label="Active polls"
          increase="10%"
          icon="fi fi-sr-people-poll"
        />
        <PollStatsCard
          total={polls.length - active}
          label="Expired polls"
          increase="10%"
          icon="fi fi-sr-people-poll"
        />
        <PollStatsCard
          total={createdToday}
          label="Created Today"
          increase="10%"
          icon="fi fi-sr-people-poll"
        />
      </div>

      <div className="row">
        {filteredPolls.map((poll) => (
          <PollCard poll={poll} key={poll.id} />
        ))}
      </div>
    </div>
  );
}

export default PollPage;
