import CryptoJS from "crypto-js"
import { useParams } from "react-router-dom"

const key = 'WsOhEgwajsuZ3vZxESqRSxirE3KGSjJf'
export const Encrypt = (id, results) => {
    return encodeURIComponent(CryptoJS.AES.encrypt(String(id) + (results ? 't' : 'f'), key).toString())
}

export const Decrypt = () => {
    const {id} = useParams()
    const decrypt = CryptoJS.AES.decrypt(id, key).toString(CryptoJS.enc.Utf8);
    const test = {
        id: decrypt.slice(0, decrypt.length - 1),
        allowed: decrypt.charAt(decrypt.length - 1) == 't'
    };
    return test
}