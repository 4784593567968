import axios, { toFormData } from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Encrypt } from "../Components/Helper";

function Participated() {
  const [polls, setPolls] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, [])

  useEffect(() => {
    async function getPolls() {
      try {
        const response = await axios.get('https://www.app.glocalpolls.com/api/participated-polls/', {
          params: {
            token: localStorage.getItem('token')
          }
        });
        setPolls(response.data);
      } catch (error) {
        toast.error('Error fetching polls');
      }
    }
  
    if (polls.length === 0) {
      getPolls();
    }
  }, []);
  
  const filteredPolls = polls.filter(poll =>
    poll.poll_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="container p-5">
      <div className="row">
        <h2 className="text-center mb-2">
          Participated Polls
        </h2>
        <p className="mb-5" style={{textAlign:'center'}}>You have participated in {polls.length} polls</p><div className="row mb-4 scan-row">
        <div className="col-lg-6 mb-3">
          <div className="qr-code-div">
            <button className="qr-code shadow-sm" data-bs-toggle="modal" data-bs-target="#scannerModal">
              scan for polling
            </button>
            <i className="fi fi-ss-qrcode"></i>
          </div>
        </div>

        <div className="col-lg-6 mb-3">
          <div className="search mb-4">
            <input onChange={(e) => setSearchQuery(e.target.value)} type="text" className="shadow-sm form-control" name="search-input" placeholder="Search a poll" />
            <i className="fi fi-br-search"></i>
          </div>
        </div>
      </div>
        {polls.length === 0 ? <p>No polls participated in</p> : 
          filteredPolls.map((poll, index) => (
            <div key={index} className="col-lg-3 col-md-6 mb-4">
              <div className="card simple_poll shadow-sm">
                <img
                  src={poll.poll_image ? 'https://www.app.glocalpolls.com/' + poll.poll_image : process.env.PUBLIC_URL + '/assets/images/dummy.webp'}
                  className="card-img-top"
                  alt="My Image"
                />

                <div className="card-body">
                  <span className="poll-active" style={{color: poll.expired ? 'red': '#2C6877'}}>{poll.expired ? 'expired' : 'active'}</span>

                  <h5 className="card-title">{poll.poll_name}</h5>
                  <p className="card-text">
                  This poll was created by {poll.username}, {poll.total_votes} people have voted this.
                  </p>
                  <div className="d-grid gap-2">
                    <Link
                      className={'btn view-poll btn-secondary'}
                      to={`/view-poll/${Encrypt(poll.id)}/`}
                      type="button"
                    >
                      Already Voted
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}

      </div>
    </div>
  );
}

export default Participated;
