import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Notification from './Notification'


function Navbar() {

  const navigate = useNavigate();
  const location = useLocation();
  const user = localStorage.getItem('token');
  const [name, setName] = useState('')
  const [notif, setNotif] = useState({})
  const [intervalId, setIntervalId] = useState()

  const isActive = (route) => {
    return location.pathname === route ? 'active' : '';
  };


  async function fetchUser() {
    try {
      const response = await axios.post('https://www.app.glocalpolls.com/api/get-user/', { token: user });
      setName(response.data['name'])
      setNotif(response.data['notifications'])
    } catch (error) {
      localStorage.removeItem('token')
      setName('')
      clearInterval(intervalId)
    }
  }

  if (!name)
    fetchUser();

  useEffect(() => {if (name) setIntervalId(setInterval(fetchUser, 30000))}, [name])


  const handleLogout = (event) => {
    event.preventDefault();
    localStorage.removeItem('token');
    setName('')
    navigate('/home');
  };

  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark shadow-sm"
      id="TOPNAVBAR"
    >
      <div className="container">
        <Link className="navbar-brand" to="/home">
          {/* Glocal Polls */}
          <img src={process.env.PUBLIC_URL + '/image.png'} width={200} draggable={false} />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className={`nav-link ${isActive('/home')}`} to="/home">
                Home
              </Link>
            </li>
            <li className="nav-item">
              {name ? (
                <Link className={`nav-link ${isActive('/created')}`} to="/created">
                  Created
                </Link>
              ) : (
                <button className="nav-link" style={{ background: 'none', border: 'none' }} data-bs-toggle="modal" data-bs-target="#registerModal" type="button">Created</button>
              )}

            </li>
            <li className="nav-item">
              {name ? (
                <Link className={`nav-link ${isActive('/participated')}`} to="/participated">
                  Participated
                </Link>
              ) : (
                <button className="nav-link" style={{ background: 'none', border: 'none' }} data-bs-toggle="modal" data-bs-target="#registerModal" type="button">Participated</button>
              )}

            </li>
            {/* Add more navigation links for other pages */}
          </ul>
          <div className="LoginSignup">
            {name ? (
              <>
                <p style={{ color: 'white', margin: '0'}}>{name}</p>
                <button
                  type="button"
                  onClick={handleLogout}
                  className="btn btn-sm secondary_button sign-up"
                  style={{margin: '6px 0px'}}
                >
                  <i className="fi fi-sr-pencil"></i>
                  logout
                </button>
                <Link to='/pricing' style={{ fontSize: '28px', textDecoration: 'none', color: 'white'}}>
                  <i className="ms-2fi fi-sr-settings"></i>
                </Link>
                <Notification list={notif} setList={setNotif}/>
              </>
            ) : (
              <>
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#loginModal"
                  className="btn btn-sm button_border sign-in"
                 
                >
                  <i className="fi fi-sr-lock"></i>
                  login
                </button>
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#registerModal"
                  className="btn btn-sm secondary_button sign-up"
                >
                  <i className="fi fi-sr-pencil"></i>
                  signup
                </button>
              </>
            )}
            
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
